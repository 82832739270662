import React, { useState, useEffect } from 'react';
import ChatSidebar from '../ChatSidebar';

const ChatAgent = ({ currentUser, agent, engine }) => {
  const [systemMessage, setSystemMessage] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [currentAgent, setCurrentAgent] = useState(agent);

  useEffect(() => {
    setCurrentAgent(agent);
  }, [agent]);

  const handleSendMessage = async (message) => {
    setChatMessages(prev => [...prev, message]);
    
    try {
      // Try Amazon recommengine endpoint
      const amazonResponse = await fetch('https://jn3m0kb9f2.execute-api.us-east-1.amazonaws.com/Production/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          message: message.content,
          systemMessage,
          userId: currentUser?.id,
          agentId: agent?.id,
          chatHistory: chatMessages
        }),
      });

      let aiResponse;
      
      if (!amazonResponse.ok) {
        // Fall back to OpenAI if Amazon fails
        console.log('Amazon API failed, falling back to OpenAI');
        const openaiResponse = await fetch('/api/chat', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            messages: [
              { role: 'system', content: systemMessage },
              ...chatMessages,
              message
            ],
            userId: currentUser?.id,
            agentId: agent?.id
          }),
        });

        if (!openaiResponse.ok) {
          throw new Error('Both Amazon and OpenAI APIs failed');
        }
        
        aiResponse = await openaiResponse.json();
      } else {
        console.log('amazonResponse: ', amazonResponse);
        aiResponse = await amazonResponse.json();
      }
      
      setChatMessages(prev => [...prev, {
        role: 'assistant',
        content: aiResponse.message || aiResponse.content,
        timestamp: new Date().toISOString()
      }]);
    } catch (error) {
      console.error('Error in chat processing:', error);
      setChatMessages(prev => [...prev, {
        role: 'system',
        content: 'Sorry, I encountered an error processing your message.',
        timestamp: new Date().toISOString()
      }]);
    }
  };

  if (!agent) {
    return <div>Loading...</div>;
  }

  return (
    <div className="h-full w-full">
      <ChatSidebar
        activeAgent={agent}
        currentAgent={currentAgent}
        currentEngine={engine || null}
        isOpen={true}
        currentUser={currentUser}
        systemMessage={systemMessage}
        sendMessage={handleSendMessage}
        chatMessages={chatMessages}
      />
    </div>
  );
};

export default ChatAgent;
