import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import General from "../../layouts/General";
import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { getFunctions, httpsCallable } from 'firebase/functions';

function CheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="h-3 w-3"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 12.75l6 6 9-13.5"
      />
    </svg>
  );
}

export function CreateSubscription() {
  const [loading, setLoading] = useState(false);
  const [showConsulting, setShowConsulting] = useState(false);

  const handleSubscription = async (priceId, mode = 'subscription') => {
    try {
      setLoading(true);
      
      const functions = getFunctions();
      const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
      
      const result = await createCheckoutSession({ 
        priceId: priceId,
        mode: mode 
      });
      
      if (!result.data || !result.data.sessionId) {
        throw new Error('Invalid response from server');
      }

      const stripe = await loadStripe("pk_live_51P99izE5vEwJ2pkMUDKRmWfaFg7GXhEv16NphVtrQLhfvCVtX6bxCEiH3pwM7HCedElUBFpuTjED40cCIqsTwRhj005zDD1OY5") //process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
      if (!stripe) {
        throw new Error('Failed to load Stripe');
      }

      const { error } = await stripe.redirectToCheckout({ 
        sessionId: result.data.sessionId 
      });
      
      if (error) {
        throw error;
      }
    } catch (error) {
      console.error('Error:', error);
      alert(error.message || 'An error occurred while processing your request');
    } finally {
      setLoading(false);
    }
  };

  return (
    <General>
      <div className="flex justify-center gap-8 p-8">
        {/* Basic Plan */}
        <Card color="gray" variant="gradient" className="w-full max-w-[20rem] p-8">
        <CardHeader
          floated={false}
          shadow={false}
          color="transparent"
          className="m-0 mb-8 rounded-none border-b border-white/10 pb-8 text-center"
        >
          <Typography
            variant="small"
            color="white"
            className="font-normal uppercase"
          >
            Basic
          </Typography>
          <Typography
            variant="h1"
            color="white"
            className="mt-6 flex justify-center gap-1 text-7xl font-normal"
          >
            <span className="mt-2 text-4xl">$</span>9{" "}
            <span className="self-end text-4xl">/mo</span>
          </Typography>
        </CardHeader>
        <CardBody className="p-0">
          <ul className="flex flex-col gap-4">
            <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-white/20 p-1">
                <CheckIcon />
              </span>
              <Typography className="font-normal">5 Engines</Typography>
            </li>
            <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-white/20 p-1">
                <CheckIcon />
              </span>
              <Typography className="font-normal">Basic features</Typography>
            </li>
            <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-white/20 p-1">
                <CheckIcon />
              </span>
              <Typography className="font-normal">Standard support</Typography>
            </li>
          </ul>
        </CardBody>
        <CardFooter className="mt-12 p-0">
          <Button
            size="lg"
            color="white"
            className="hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
            ripple={false}
            disabled={loading}
            fullWidth={true}
            onClick={() => handleSubscription('price_1QVi84E5vEwJ2pkMrP9bI59b')}
          >
            {loading ? 'Processing...' : 'Select Basic Plan'}
          </Button>
        </CardFooter>
      </Card>

      {/* Pro Plan */}
      <Card color="blue" variant="gradient" className="w-full max-w-[20rem] p-8">
        <CardHeader
          floated={false}
          shadow={false}
          color="transparent"
          className="m-0 mb-8 rounded-none border-b border-white/10 pb-8 text-center"
        >
          <Typography
            variant="small"
            color="white"
            className="font-normal uppercase"
          >
            Pro
          </Typography>
          <Typography
            variant="h1"
            color="white"
            className="mt-6 flex justify-center gap-1 text-7xl font-normal"
          >
            <span className="mt-2 text-4xl">$</span>29{" "}
            <span className="self-end text-4xl">/mo</span>
          </Typography>
        </CardHeader>
        <CardBody className="p-0">
          <ul className="flex flex-col gap-4">
            <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-white/20 p-1">
                <CheckIcon />
              </span>
              <Typography className="font-normal">Unlimited Engines</Typography>
            </li>
            <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-white/20 p-1">
                <CheckIcon />
              </span>
              <Typography className="font-normal">Advanced features</Typography>
            </li>
            <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-white/20 p-1">
                <CheckIcon />
              </span>
              <Typography className="font-normal">Priority support</Typography>
            </li>
            <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-white/20 p-1">
                <CheckIcon />
              </span>
              <Typography className="font-normal">Custom integrations</Typography>
            </li>
          </ul>
        </CardBody>
        <CardFooter className="mt-12 p-0">
          <Button
            size="lg"
            color="white"
            className="hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
            ripple={false}
            fullWidth={true}
            disabled={loading}
            onClick={() => handleSubscription('price_1QVi8LE5vEwJ2pkMhyy5Wsqr')}
          >
            {loading ? 'Processing...' : 'Select Pro Plan'}
          </Button>
        </CardFooter>
        </Card>

        {/* Consulting Plan */}
        {showConsulting && (
          <Card color="teal" variant="gradient" className="w-full max-w-[20rem] p-8">
            <CardHeader
              floated={false}
              shadow={false}
              color="transparent"
              className="m-0 mb-8 rounded-none border-b border-white/10 pb-8 text-center"
            >
              <Typography
                variant="small"
                color="white"
                className="font-normal uppercase"
              >
                Hourly Consulting
              </Typography>
              <Typography
                variant="h1"
                color="white"
                className="mt-6 flex justify-center gap-1 text-7xl font-normal"
              >
                <span className="mt-2 text-4xl">$</span>75{" "}
                <span className="self-end text-4xl">/hr</span>
              </Typography>
            </CardHeader>
            <CardBody className="p-0">
              <ul className="flex flex-col gap-4">
                <li className="flex items-center gap-4">
                  <span className="rounded-full border border-white/20 bg-white/20 p-1">
                    <CheckIcon />
                  </span>
                  <Typography className="font-normal">Hourly Consulting</Typography>
                </li>
                <li className="flex items-center gap-4">
                  <span className="rounded-full border border-white/20 bg-white/20 p-1">
                    <CheckIcon />
                  </span>
                  <Typography className="font-normal">Save 17% for a limited time!</Typography>
                </li>
              </ul>
            </CardBody>
            <CardFooter className="mt-12 p-0">
              <Button
                size="lg"
                color="white"
                className="hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
                ripple={false}
                fullWidth={true}
                disabled={loading}
                onClick={() => handleSubscription('price_1QXBWUE5vEwJ2pkMDGbkW4Zs', 'payment')}
              >
                {loading ? 'Processing...' : 'Select Consulting Plan'}
              </Button>
            </CardFooter>
          </Card>
        )}
      </div>
    </General>
  );
}

export default CreateSubscription;
