import React, { useState, useEffect } from 'react';
import General from '../layouts/General';
import StripeConnectForm from './StripeConnectForm';
import { getDoc, doc } from "firebase/firestore";
import { db, auth } from '../FirebaseConfig';
import { Button, Typography } from '@material-tailwind/react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { AuthProvider, useAuth } from '../contexts/AuthContext';
import AccountStatus from './AccountStatus';

function Settings() {
  // const [currentUser, setCurrentUser] = useState(null);
  const { currentUser, loading } = useAuth();
  const [stripeAccount, setStripeAccount] = useState(null);
  const [showDangerZone, setShowDangerZone] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [userStatus, setUserStatus] = useState(null);

  const getStripeAccount = async (userId) => {
    const configDoc = await getDoc(doc(db, "recommengine", "users", userId, "config"));
    return configDoc.data()?.stripe || null;
  }

  const getUserStatus = async () => {
    const functions = getFunctions();
    const getUserStatusFn = httpsCallable(functions, 'getUserStatus');
    try {
      const result = await getUserStatusFn({ uid: currentUser.uid });
      return result.data;
    } catch (error) {
      console.error('Error fetching user status:', error);
      return null;
    }
  }

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (user) {
        // setCurrentUser(user);
        getStripeAccount(user.uid).then((account) => {
          setStripeAccount(account);
        });
        getUserStatus().then((status) => {
          console.log('user status: ', status)
          setUserStatus(status);
        });
      } else {
        // setCurrentUser(null);
      }
    });
  }, []);

  return (
    <General title="Settings">
      <div className="text-gray-400">
        <StripeConnectForm 
            currentUser={currentUser} 
            stripeAccount={stripeAccount} 
            onRefresh={() => getStripeAccount(currentUser.uid).then((account) => {
              setStripeAccount(account);
            })}
        />

        <AccountStatus userStatus={userStatus} />

        <div className='flex flex-col gap-4 w-full justify-center items-center'>
            <div 
                className='flex flex-row justify-between items-center cursor-pointer' 
                onClick={() => setShowDangerZone(!showDangerZone)}
            >
                <Typography variant='h6' className='text-gray-500'>Danger Zone</Typography>
                <Typography className="ml-16 text-xl">
                    {showDangerZone ? '▼' : '▶'}
                </Typography>
            </div>
            
            {showDangerZone && (
                <div className='flex flex-row justify-end'>
                    <Button 
                        color="red" 
                        onClick={() => setShowDeleteDialog(true)}
                    >
                        Delete Account
                    </Button>
                </div>
            )}
        </div>

        {showDeleteDialog && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <div className="bg-white p-6 rounded-lg shadow-xl">
                    <Typography variant="h6" className="mb-4">
                        Are you sure you want to delete your account?
                    </Typography>
                    <Typography className="mb-6 text-gray-600">
                        This action cannot be undone.
                    </Typography>
                    <div className="flex justify-end gap-4">
                        <Button 
                            color="gray" 
                            onClick={() => setShowDeleteDialog(false)}
                        >
                            Cancel
                        </Button>
                        <Button 
                            color="red" 
                            onClick={() => {
                                // TODO: Add delete account logic here
                                setShowDeleteDialog(false);
                            }}
                        >
                            Delete
                        </Button>
                    </div>
                </div>
            </div>
        )}
      </div>
    </General>
  );
}

export default Settings; 