import React, { useState, useEffect } from 'react';
import { Typography } from "@material-tailwind/react";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useAuth } from '../../contexts/AuthContext';
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/solid";

export default function UsageStats() {
    const [isExpanded, setIsExpanded] = useState(true);
    const [usageData, setUsageData] = useState(null);
    const { currentUser } = useAuth();
    const TOKEN_LIMIT = 5000; // Add token limit constant

    const getUsageData = async () => {
        try {
            const lambdaResponse = await fetch(
                `https://d7hshasr2tbnv3xqrbdyxf732e0zkntr.lambda-url.us-east-1.on.aws/?uid=${currentUser.uid}`
            );
            return await lambdaResponse.json();
        } catch (error) {
            console.error('Error fetching usage data:', error);
            return null;
        }
    }

    useEffect(() => {
        if (currentUser) {
            getUsageData().then(setUsageData);
        }
    }, [currentUser]);

    return (
        <div className="p-4 bg-white rounded-lg shadow-sm border border-blue-gray-50">
            <div className="flex justify-between items-center mb-2">
                <Typography variant="h6" color="blue-gray" className="text-sm font-semibold">
                    Usage Statistics
                </Typography>
                <button 
                    onClick={() => setIsExpanded(!isExpanded)} 
                    className="text-blue-gray-500"
                >
                    {isExpanded ? 
                        <ChevronUpIcon className="h-3.5 w-3.5" /> : 
                        <ChevronDownIcon className="h-3.5 w-3.5" />
                    }
                </button>
            </div>
            {usageData && (
                <div className="space-y-2">
                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                        <div 
                            className={`h-2.5 rounded-full ${
                                (usageData.total_tokens / TOKEN_LIMIT) > 0.9 
                                    ? 'bg-red-500' 
                                    : (usageData.total_tokens / TOKEN_LIMIT) > 0.7 
                                        ? 'bg-yellow-500' 
                                        : 'bg-green-500'
                            }`}
                            style={{ width: `${Math.min((usageData.total_tokens / TOKEN_LIMIT) * 100, 100)}%` }}
                        ></div>
                    </div>
                    <div className="flex justify-between text-sm">
                        <span className="text-blue-gray-600">
                            {usageData.total_tokens} / {TOKEN_LIMIT} tokens used
                        </span>
                        <span className="text-blue-gray-600">
                            {Math.round((usageData.total_tokens / TOKEN_LIMIT) * 100)}%
                        </span>
                    </div>
                </div>
            )}
            {isExpanded && usageData && (
                <div className="space-y-1 text-sm">
                    <div className="flex justify-between">
                        <span className="font-medium">Input Tokens:</span>
                        <span>{usageData.input_tokens}</span>
                    </div>
                    <div className="flex justify-between">
                        <span className="font-medium">Output Tokens:</span>
                        <span>{usageData.output_tokens}</span>
                    </div>
                    <div className="flex justify-between">
                        <span className="font-medium">Conversations:</span>
                        <span>{usageData.conversation_count}</span>
                    </div>
                    <div className="flex justify-between">
                        <span className="font-medium">Since:</span>
                        <span>{new Date(usageData.since).toLocaleDateString()}</span>
                    </div>
                    <div className="flex justify-between pt-1 mt-1 border-t border-blue-gray-100">
                        <span className="font-medium">Tokens Remaining:</span>
                        <span className="font-medium">{TOKEN_LIMIT - usageData.total_tokens}</span>
                    </div>
                </div>
            )}
        </div>
    );
} 