import React from 'react';
import { Typography } from '@material-tailwind/react';

function AccountStatus({ userStatus }) {
  if (!userStatus) return null;

  return (
    <div className="w-full max-w-2xl mx-auto mb-8">
      <Typography variant="h6" className="mb-4">Account Status</Typography>
      <div className="border rounded-lg overflow-hidden">
        <table className="w-full">
          <tbody>
            {Object.entries(userStatus).map(([key, value]) => (
              <tr key={key} className="border-b last:border-b-0">
                <td className="p-3 bg-gray-50 font-medium capitalize">
                  {key.replace(/_/g, ' ')}
                </td>
                <td className="p-3">
                  {typeof value === 'boolean' 
                    ? value ? 'Yes' : 'No'
                    : String(value)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AccountStatus; 