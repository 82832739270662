import React, { useState, useEffect } from 'react'
import {
    Button,
    Checkbox,
    Typography,
    Input,
    List,
    ListItem,
    Card,
    Carousel,
    CardBody,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";
// import RegularButton from './buttons/RegularButton';
import LoadingIndicator from './LoadingIndicator';
import { app } from '../FirebaseConfig';
import { getFunctions, httpsCallable } from 'firebase/functions';

export default function AgentContent({ currentUser, myAgents, refreshAgents }) {
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [sortedAgents, setSortedAgents] = useState([...myAgents]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [loading, setLoading] = useState(true);

    const TABLE_HEAD = [
        // { label: "ID", key: "id" },
        { label: "Name", key: "name" },
        { label: "Created At", key: "createdAt" },
        { label: "Role", key: "description" },
        { label: "Actions", key: "actions" }
    ];

    useEffect(() => {
        let sorted = [...myAgents];
        if (sortConfig.key) {
            sorted.sort((a, b) => {
                const aValue = a.data[sortConfig.key];
                const bValue = b.data[sortConfig.key];
                
                if (aValue < bValue) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        setSortedAgents(sorted);
        setLoading(false);
    }, [myAgents, sortConfig]);

    const handleSort = (key) => {
        setSortConfig((prevConfig) => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
        }));
    };

    const handleOpen = (agent) => {
        setSelectedAgent(agent);
        setOpenDialog(true);
    };

    const handleArchive = async () => {
        if (!selectedAgent) return;
        
        try {
            const functions = getFunctions(app);
            const archiveAgent = httpsCallable(functions, 'archiveAgent');
            
            const result = await archiveAgent({
                uid: currentUser.uid,
                id: selectedAgent.id
            });

            console.log(result);

            if (result.data) {
                setOpenDialog(false);
                setSelectedAgent(null);
                // refresh agents
                refreshAgents(currentUser.uid);
            }
        } catch (error) {
            console.error('Error archiving agent:', error);
        }
    };

    if (loading) {
        return <LoadingIndicator />;
    }

    return (
        <div className='flex flex-col mt-2'>
            {/* <RegularButton text="Create New Agent" url="/create-new-agent" /> */}
            <Card className="h-full w-full overflow-scroll">
                <table className="w-full min-w-max table-auto text-left">
                    <thead>
                    <tr>
                        {TABLE_HEAD.map((head) => (
                            <th
                                key={head.key}
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 cursor-pointer"
                                onClick={() => handleSort(head.key)}
                            >
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none opacity-70 flex items-center gap-2"
                                >
                                    {head.label}
                                    {sortConfig.key === head.key && (
                                        <span>{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>
                                    )}
                                </Typography>
                            </th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {sortedAgents.map((agent, index) => {
                        const isLast = index === sortedAgents.length - 1;
                        const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
                        // console.log(agent)
            
                        return (
                        <tr key={`agent-${agent.id}`}>
                            <td className={classes}>
                            <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                                as="a"
                                href={'/agents/' + agent.id}
                            >
                                {agent.data.name}
                            </Typography>
                            </td>
                            <td className={classes}>
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                >
                                    {new Date(agent.data.createdAt).toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })}
                                </Typography>
                            </td>
                            <td className={classes}>
                            <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                            >
                                {agent.data.description}
                            </Typography>
                            </td>
                            {/* <td className={classes}>
                            {agent.features && Object.keys(agent.features).map((feature) => (
                                agent.features[feature] === true && (
                                    <Typography color="blue-gray">{feature}</Typography>
                                )
                            ))}
                            </td> */}
                            <td className={classes}>
                                <Button
                                    size="sm"
                                    color="red"
                                    variant="text"
                                    className="flex items-center gap-2"
                                    onClick={() => handleOpen(agent)}
                                >
                                    Archive
                                </Button>
                            </td>
                            {/* <td className={classes}>
                            <Typography
                                as="a"
                                href="#"
                                variant="small"
                                color="blue-gray"
                                className="font-medium"
                            >
                                Edit
                            </Typography>
                            </td> */}
                        </tr>
                        );
                    })}
                    </tbody>
                </table>
            </Card>

            <Dialog open={openDialog} handler={() => setOpenDialog(false)}>
                <DialogHeader>Confirm Archive</DialogHeader>
                <DialogBody divider>
                    Are you sure you want to archive {selectedAgent?.name}? This action cannot be undone.
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="gray"
                        onClick={() => setOpenDialog(false)}
                        className="mr-1"
                    >
                        <span>Cancel</span>
                    </Button>
                    <Button
                        variant="gradient"
                        color="red"
                        onClick={handleArchive}
                    >
                        <span>Confirm Archive</span>
                    </Button>
                </DialogFooter>
            </Dialog>
        </div>
    );
}