import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { db, auth } from '../../FirebaseConfig';
import { doc, getDoc, setDoc } from "firebase/firestore";
import General from '../../layouts/General';

function Workflow({ isEditing }) {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const [activeWorkflow, setActiveWorkflow] = useState({});
    const [isEditMode, setIsEditMode] = useState(isEditing);

    const getWorkflow = async () => {
        if (!currentUser) return false;

        try {
            const docRef = await getDoc(doc(db, "recommengine/users/"+ currentUser.uid +"/data/workflows", id));
            const docSnap = docRef.data();
            console.log('workflow data: ', docSnap);
            return docSnap;
        } catch (e) {
            console.error("Error getting workflow: ", e);
            return false;
        }
    };

    const handleSave = async () => {
        if (!currentUser) return;

        try {
            await setDoc(doc(db, "recommengine/users/"+ currentUser.uid +"/data/workflows", id), activeWorkflow);
            setIsEditMode(false);
        } catch (e) {
            console.error("Error saving workflow: ", e);
        }
    };

    useEffect(() => {
        console.log('workflow id: ', id);

        auth.onAuthStateChanged(user => {
            if (user && id) {
                setCurrentUser(user);
                if (loading) {
                    getWorkflow().then((result) => {
                        if (result) {
                            setActiveWorkflow(result);
                            console.log('active workflow: ', result);
                            setLoading(false);
                        }
                    });
                }
            } else {
                setCurrentUser(null);
            }
        });
    }, [currentUser]);

    return (
        <General title={isEditMode ? "Edit Workflow" : "Workflow"}>
            {!loading && (
                <div className="container mx-auto px-4">
                    <button
                        onClick={() => window.location.href = '/workflows'}
                        className="mb-4 text-blue-500 hover:text-blue-700 flex items-center"
                    >
                        <span className="mr-1">←</span> Back to Workflows
                    </button>
                    {isEditMode ? (
                        <>
                            <input
                                type="text"
                                className="text-2xl font-bold mb-6 p-2 border rounded"
                                value={activeWorkflow.name}
                                onChange={(e) => setActiveWorkflow({...activeWorkflow, name: e.target.value})}
                            />
                            <div className="mt-4">
                                <button 
                                    onClick={handleSave}
                                    className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
                                >
                                    Save
                                </button>
                                <button 
                                    onClick={() => setIsEditMode(false)}
                                    className="bg-gray-500 text-white px-4 py-2 rounded"
                                >
                                    Cancel
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <h2 className="text-2xl font-bold mb-6">{activeWorkflow.name}</h2>
                            <p className="text-gray-600">
                                {/* Created: {new Date(activeWorkflow.created).toLocaleDateString()} */}
                            </p>
                        </>
                    )}
                </div>
            )}
        </General>
    );
}

export default Workflow;
