import React, { useEffect, useState } from "react";
import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
  Dialog,
} from "@material-tailwind/react";

import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  updateProfile,
} from "firebase/auth";

import {
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";

import { auth, db } from "../FirebaseConfig";

import General from "../layouts/General";

// import { FeatureCardsCarousel } from './FeatureCardsCarousel';
// import { FeatureCardsCarousel2 } from './FeatureCardsCarousel2';
import { ExamplesCarousel } from './ExamplesCarousel';
import { BusinessTypeGrid } from './BusinessTypeGrid';
import { BetaDownloadButtons } from './BetaDownloadButtons';
import { ComposableAppsSection } from './ComposableAppsSection';
import { EngineOverview } from './EngineOverview';
import { PreLaunchNotice } from './PreLaunchNotice';
import { FeaturesList } from './FeaturesList';
import { CTAButton } from './CTAButton';

const googleProvider = new GoogleAuthProvider();

export function RegistrationForm({ signIn, signOut, register }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [currentForm, setCurrentForm] = useState('register');
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [error, setError] = useState("");
  const [currentFeatureSlide, setCurrentFeatureSlide] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const scrollToForm = (formType = 'register') => {
    setCurrentForm(formType);
    setTimeout(() => {
      const formElement = document.querySelector('#auth-form');
      if (formElement) {
        formElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 100);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      if (currentForm === 'register') {
        if (!name.trim()) {
          setError("Please enter your name");
          return;
        }
        await registerWithEmailAndPassword(name.trim(), email, password);
      } else {
        await logInWithEmailAndPassword(email, password);
        window.location.href = '/';
      }
    } catch (err) {
      console.error("Authentication error:", err);
    }
  };

  useEffect(() => {
    if (signOut) {
      logout();
      setCurrentForm('login');
    }
    if (signIn) {
      setCurrentForm('login');
      scrollToForm('login');
    }
    if (register) {
      setCurrentForm('register');
      scrollToForm('register');
    }
  }, [signOut, signIn, register]);

  const handleOpenModal = (business) => {
    setSelectedBusiness(business);
    setOpenModal(true);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? 4 : prev - 1));
  };

  const handleNextSlide = () => {
    setCurrentSlide((prev) => (prev === 4 ? 0 : prev + 1));
  };

  const handleSlideChange = (index) => {
    setCurrentSlide(index);
  };

  const resetForm = () => {
    setEmail("");
    setPassword("");
    setName("");
    setError("");
  };

  const toggleForm = (newForm) => {
    resetForm();
    scrollToForm(newForm);
  };

  const showMarketingContent = !signIn && !signOut && !register;

  useEffect(() => {
    if (isHovered) return; // Don't start timer if card is being hovered

    const timer = setInterval(() => {
      setCurrentFeatureSlide(prev => (prev + 1) % 6);
    }, 3000);

    return () => clearInterval(timer);
  }, [isHovered]); // Add isHovered to dependencies

  const signInWithGoogle = async () => {
    try {
      googleProvider.setCustomParameters({
        prompt: 'select_account'
      });
      
      const res = await signInWithPopup(auth, googleProvider);
      const user = res.user;
      const q = query(collection(db, "users"), where("uid", "==", user.uid));
      const docs = await getDocs(q);
      if (docs.docs.length === 0) {
        await addDoc(collection(db, "users"), {
          uid: user.uid,
          name: user.displayName,
          authProvider: "google",
          email: user.email,
        });
      }
      window.location.href = '/';
    } catch (err) {
      console.error(err);
      setErrorMessage(err.message);
      setErrorDialogOpen(true);
    }
  };

  const logInWithEmailAndPassword = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      console.error(err);
      let userMessage = "Failed to sign in. Please try again.";
      if (err.code === 'auth/user-not-found' || err.code === 'auth/wrong-password') {
        userMessage = "Invalid email or password";
      } else if (err.code === 'auth/too-many-requests') {
        userMessage = "Too many failed attempts. Please try again later.";
      }
      setError(userMessage);
      throw err;
    }
  };

  const registerWithEmailAndPassword = async (name, email, password) => {
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      const user = res.user;
      await updateProfile(user, {
        displayName: name,
      });

      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: name,
        authProvider: "local",
        email,
      });
      window.location.href = '/';
    } catch (err) {
      console.error(err);
      setErrorMessage(err.message);
      setErrorDialogOpen(true);
    }
  };

  const sendPasswordReset = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      alert("Password reset link sent!");
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const logout = () => {
    signOut(auth);
    window.location.href = '/'; // Add redirect to root path
  };

  return (
    <General>
      {/* Only show marketing content if not signing in/out/registering */}
      {showMarketingContent ? (
        <>
          {/* Hero Section */}
          <section className="relative overflow-hidden bg-gradient-to-b from-slate-blue-50/90 via-military-green-50/80 to-white py-24 border-b border-military-green-200">
            {/* Enhanced decorative background elements */}
            <div className="absolute inset-0 overflow-hidden">
              {/* Gradient orbs */}
              <div className="absolute -top-1/2 -left-1/4 w-1/2 h-full bg-gradient-to-br from-military-green-200/40 to-slate-blue-200/40 rounded-full blur-3xl transform rotate-12 animate-pulse"></div>
              <div className="absolute -bottom-1/2 -right-1/4 w-1/2 h-full bg-gradient-to-tl from-slate-blue-200/40 to-military-green-200/40 rounded-full blur-3xl transform -rotate-12 animate-pulse"></div>
              
              {/* Subtle pattern overlay */}
              <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHBhdHRlcm4gaWQ9ImdyaWQiIHdpZHRoPSI2MCIgaGVpZ2h0PSI2MCIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHBhdGggZD0iTSAxMCAwIEwgMCAwIDAgMTAiIGZpbGw9Im5vbmUiIHN0cm9rZT0icmdiYSgxMDAsIDEwMCwgMTAwLCAwLjEpIiBzdHJva2Utd2lkdGg9IjEiLz48L3BhdHRlcm4+PC9kZWZzPjxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JpZCkiLz48L3N2Zz4=')] opacity-30"></div>
              
              {/* Subtle glass effect */}
              <div className="absolute inset-0 bg-white/20 backdrop-blur-[1px]"></div>
            </div>

            <div className='relative max-w-7xl mx-auto px-4'>
              <PreLaunchNotice />
              
              <Typography variant='h1' className='text-center text-military-green-800 font-bold text-5xl md:text-6xl lg:text-7xl tracking-tight leading-none mb-6 mt-8'>
                <span className="relative inline-block">
                  <span className="relative bg-gradient-to-r from-military-green-600 to-slate-blue-500 bg-clip-text text-transparent">
                    Start Your Engines
                  </span>
                </span>
                <span className="animate-[bounce_1s_ease-in-out_5]">🚀</span>
              </Typography>

              {/* Enhanced tagline with better contrast */}
              <Typography className="text-center mt-6 text-lg md:text-xl text-military-green-700 max-w-3xl mx-auto opacity-0 animate-[fadeIn_0.5s_ease-out_0.2s_forwards] font-medium">
                Recommengine empowers you to create custom engines for business or personal use.
              </Typography>
             
              <CTAButton 
                text={"Try Recommengine Now"}
                onClick={() => {
                  setCurrentForm('register');
                  scrollToForm('register');
                }}
                description={"Try Recommengine for free."}
                variant="highlight"
              />

              {/* <CTAButton 
                text={"Create Your Free Account"}
                onClick={() => {
                  setCurrentForm('register');
                  scrollToForm('register');
                }}
                description={"Basic features are free forever."}
                variant="default"
              /> */}
            </div>
          </section>

          {/* <section className="bg-gradient-to-b from-white via-military-green-50 to-white py-16 border-b border-military-green-200">
            <div className="max-w-7xl mx-auto px-4">
              <Typography variant="h2" className="text-center text-4xl md:text-5xl font-bold mb-8">
                <span className="bg-gradient-to-r from-military-green-600 to-slate-blue-500 bg-clip-text text-transparent">
                  Endless Possibilities
                </span>
                <span className="block text-lg md:text-xl text-military-green-600 mt-2 font-normal">
                  See how businesses are transforming with our engines
                </span>
              </Typography>
              
              <ExamplesCarousel 
                currentSlide={currentSlide}
                handlePrevSlide={handlePrevSlide}
                handleNextSlide={handleNextSlide}
                handleSlideChange={handleSlideChange}
              />
            </div>
          </section> */}

          {/* Engine Explanation Section */}
          <EngineOverview />

          <ComposableAppsSection scrollToForm={scrollToForm} />

          {/* Business Types Section */}
          <section className="bg-gradient-to-b from-white via-military-green-50 to-white py-16 border-b border-military-green-200">
            <BusinessTypeGrid 
              handleOpenModal={handleOpenModal}
              scrollToForm={scrollToForm}
            />
          </section>
        </>
      ) : null}

      {/* Update Registration Form Section with id and show login form when needed */}
      <section id="auth-form" className="py-16 bg-white">
        <div className="max-w-md mx-auto px-4">
          {(currentForm === 'register' || currentForm === 'login') && (
            <form onSubmit={handleSubmit} className="space-y-6">
              <Typography variant="h4" className="text-center font-bold text-military-green-800 mb-6">
                {currentForm === 'register' ? 'Create Your Account' : 'Sign In'}
              </Typography>

              {/* Add helpful description */}
              <Typography className="text-center text-military-green-600 text-sm mb-6">
                {currentForm === 'register' 
                  ? 'Join thousands of businesses already using Recommengine to streamline their operations.'
                  : 'Welcome back! Sign in to access your engine dashboard.'}
              </Typography>
              
              {error && (
                <div className="bg-red-50 text-red-500 p-3 rounded-sm mb-4">
                  {error}
                </div>
              )}

              {currentForm === 'register' && (
                <>
                  <Input
                    type="text"
                    label="Full Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    className="!border-military-green-200 focus:!border-military-green-500"
                  />
                  {/* <Typography className="text-xs text-military-green-500 -mt-4">
                    This will be displayed in your engine dashboard
                  </Typography> */}
                </>
              )}

              <Input
                type="email"
                label="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="!border-military-green-200 focus:!border-military-green-500"
              />

              <Input
                type="password"
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="!border-military-green-200 focus:!border-military-green-500"
              />

              <Button
                type="submit"
                className="w-full bg-gradient-to-r from-military-green-500 to-slate-blue-500"
              >
                {currentForm === 'register' ? 'Create Account' : 'Sign In'}
              </Button>

              <div className="relative my-6">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-military-green-200"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-military-green-500">Or continue with</span>
                </div>
              </div>

              <Button
                onClick={signInWithGoogle}
                variant="outlined"
                className="w-full !border-military-green-200 text-military-green-700 flex items-center justify-center"
              >
                <img
                  src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                  alt="Google"
                  className="w-5 h-5 mr-2"
                />
                <span>Google</span>
              </Button>

              <div className="text-center text-sm text-military-green-600">
                {currentForm === 'register' ? (
                  <>
                    Already have an account?{' '}
                    <button
                      type="button"
                      onClick={() => toggleForm('login')}
                      className="text-military-green-700 font-semibold hover:text-military-green-800"
                    >
                      Sign in
                    </button>
                  </>
                ) : (
                  <>
                    Don't have an account?{' '}
                    <button
                      type="button"
                      onClick={() => toggleForm('register')}
                      className="text-military-green-700 font-semibold hover:text-military-green-800"
                    >
                      Create one
                    </button>
                  </>
                )}
              </div>
            </form>
          )}
        </div>
      </section>

      {/* Beta Download Section */}
      <section className="py-16 bg-gradient-to-b from-white to-military-green-50 border-t border-military-green-200">
        <div className="max-w-3xl mx-auto px-4 text-center">
          <Typography variant="h4" className="text-military-green-800 font-bold mb-6">
            Try the Beta Version
          </Typography>
          <Typography className="text-military-green-600 mb-8">
            Experience our mobile apps firsthand. Download the beta version for iOS or Android and start building your engine today.
          </Typography>
          <BetaDownloadButtons />
          <Typography className="text-military-green-500 text-sm mt-6">
            Available now for early access users
          </Typography>
        </div>
      </section>

      <section className="py-16 bg-gradient-to-b from-white to-military-green-50 border-t border-military-green-200">
        <div className="max-w-7xl mx-auto px-4">
          <FeaturesList />
        </div>
      </section>

      <Dialog 
        open={openModal} 
        handler={() => setOpenModal(false)} 
        className="max-w-[800px] overflow-hidden bg-white"
      >
        {selectedBusiness && (
          <div className="flex">
            {/* Left accent border now part of a separate div */}
            <div className="w-1 bg-military-green-500"></div>
            
            {/* Main content with cleaned up spacing */}
            <div className="flex-1 p-8">
              <div className="flex items-center gap-4 mb-8">
                <div className="p-2 bg-military-green-50 rounded-sm">
                  <img src={selectedBusiness.icon} alt={selectedBusiness.title} className="w-10 h-10" />
                </div>
                <Typography variant="h4" className="font-bold text-military-green-800">
                  {selectedBusiness.title}
                </Typography>
              </div>
              
              <Typography className="mb-8 text-military-green-600">
                {selectedBusiness.description}
              </Typography>

              <div className="space-y-6">
                <Typography variant="h6" className="font-semibold text-military-green-700">
                  Key Features:
                </Typography>
                <ul className="list-none space-y-3">
                  {/* Added icons and better spacing for list items */}
                  {['Customized scheduling and booking system',
                    'Client management and communication tools',
                    'Industry-specific workflow automation',
                    'Mobile-first design for field operations',
                    'Integrated payment processing'
                  ].map((feature, index) => (
                    <li key={index} className="flex items-center gap-3 text-military-green-600">
                      <svg className="w-5 h-5 text-military-green-500 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                      </svg>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="mt-8 flex justify-end gap-4">
                <Button 
                  variant="text"
                  className="text-military-green-600 hover:text-military-green-700"
                  onClick={() => setOpenModal(false)}
                >
                  Close
                </Button>
                <div className="flex flex-col items-end gap-2">
                  <BetaDownloadButtons />
                </div>
              </div>
            </div>
          </div>
        )}
        {!selectedBusiness && (
          <div className="flex flex-col items-center justify-center h-full">
            <Typography variant="h4" className="text-military-green-800 font-bold mb-6">
              No business selected
            </Typography>
          </div>
        )}
      </Dialog>

      <Dialog 
        open={errorDialogOpen} 
        handler={() => setErrorDialogOpen(false)}
        className="max-w-[400px] bg-white"
      >
        <div className="flex flex-col p-6">
          <Typography variant="h6" className="text-red-500 mb-4">
            Error
          </Typography>
          <Typography className="text-military-green-700 mb-6">
            {errorMessage}
          </Typography>
          <Button 
            onClick={() => setErrorDialogOpen(false)}
            className="bg-military-green-500"
          >
            Close
          </Button>
        </div>
      </Dialog>
    </General>
  );
}