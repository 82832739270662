import React, { useEffect, useState } from 'react'
import LoadingIndicator from './LoadingIndicator';

// import { Button, Checkbox, Typography, Input, List, ListItem, Card, Carousel } from "@material-tailwind/react";
// import { getFirestore, collection, addDoc, query, where, getDocs } from "firebase/firestore";

import General from '../layouts/General';
import AgentContent from './AgentContent';

import { db, auth, app } from '../FirebaseConfig'
import { getFunctions, httpsCallable } from 'firebase/functions';
import RegularButton from './buttons/RegularButton';

const getMyAgents = async (userId) => {
    const functions = getFunctions(app);
    const getMyAgents = httpsCallable(functions, 'getMyAgents');
    
    const result = await getMyAgents({
        uid: userId
    });

    if (result.data) {
        const activeAgents = result.data.filter(agent => !agent.data.archived);
        return activeAgents;
    } else {
        console.log("Error getting agents");
        return [];
    }
}

export default function Agents() {
    const [myAgents, setMyAgents] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [fetchingAgents, setFetchingAgents] = useState(false);

    const refreshAgents = async (userId) => {
        setFetchingAgents(true);
        const agents = await getMyAgents(userId);
        console.log(agents);
        setMyAgents(agents);
        setFetchingAgents(false);
    };

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if (user) {
                setCurrentUser(user);
                refreshAgents(user.uid);
            } else {
                setCurrentUser(null);
            }
            setLoading(false);
        })        
    }, [])

    if (loading) {
        return <LoadingIndicator />;
    }

    return (
        <General title="Agents">
            {currentUser && (
                fetchingAgents ? (
                    <LoadingIndicator />
                ) : (
                    <AgentContent 
                        currentUser={currentUser} 
                        myAgents={myAgents} 
                        refreshAgents={refreshAgents}
                    />
                )
            )}
        </General>
    )
}