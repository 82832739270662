import React from 'react';
import {
    Button,
    Typography,
    Input,
    Card,
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";
// import { doc, updateDoc } from "firebase/firestore";
// import { db } from '../../FirebaseConfig';
// import RegularButton from '../buttons/RegularButton';
import MapSection from './MapSection';
import CameraSection from './CameraSection';
import CalendarSection from './CalendarSection';
// import ChatSectionContent from './ChatSectionContent';
import Recommengine from '../Recommengine';

export default function EngineTabContent({
    activeTab,
    setActiveTab,
    myEngine,
    mapPositions,
    enginePhotos,
    calendarEvents,
    chatMessages,
    currentUser,
    id,
    errorMessage,
    openArchiveDialog,
    openUnarchiveDialog,
    setOpenArchiveDialog,
    setOpenUnarchiveDialog,
    handleArchive,
    handleUnarchive,
    getEnginePositions,
    deletePhoto,
    getEnginePhotos,
    refreshEvents,
    getEngineChatMessages,
    FEATURE_ORDER,
    onToggleContent
}) {
    console.log('EngineTabContent enginePhotos:', enginePhotos);

    const tabItems = FEATURE_ORDER
        // .filter(feature => myEngine.features?.[feature])
        .map(feature => {
            const getIcon = (featureName) => {
                switch(featureName) {
                    case 'map': return '📍';
                    case 'camera': return '📷';
                    case 'calendar': return '📅';
                    case 'chat': return '💬';
                    default: return '';
                }
            };

            return (
                <Tab 
                    key={feature}
                    value={feature.charAt(0).toUpperCase() + feature.slice(1)} 
                    className="z-0 h-auto py-2 hover:bg-military-green-50/80 transition-colors"
                    activeClassName="!text-military-green-900 !font-bold !bg-military-green-100"
                >
                    <div className="flex flex-col sm:flex-row items-center gap-1 sm:gap-2">
                        <span className="text-xl">{getIcon(feature)}</span>
                        <span className="text-xs sm:text-sm">
                            {feature.charAt(0).toUpperCase() + feature.slice(1)}
                        </span>
                    </div>
                </Tab>
            );
        });

    return (
        <div>
            {/* <button
                onClick={onToggleContent}
                className="text-gray-600 underline cursor-pointer p-1"
            >
                ← Back to Stats
            </button> */}
            <div className="w-full p-4 flex-1 flex flex-col mb-16 md:mb-0 min-h-[calc(100vh-120px)]">
                <Tabs value={activeTab}>
                    <TabsHeader className="bg-military-green-50 hidden md:flex sticky top-0 z-10">
                        {tabItems}
                    </TabsHeader>

                    <TabsBody 
                        className="overflow-y-auto z-20 flex-1" 
                        style={{ minHeight: 'calc(100vh - 300px)' }}
                        animate={{
                            initial: { y: 50 },
                            mount: { y: 0 },
                            unmount: { y: 50 },
                        }}
                    >
                        {/* {myEngine.features?.map && ( */}
                        {true && (
                            <TabPanel value="Map" data-value="Map">
                                <MapSection 
                                    mapPositions={mapPositions}
                                    engineId={id}
                                    onPositionAdded={getEnginePositions}
                                />
                            </TabPanel>
                        )}

                        {/* {myEngine.features?.camera && ( */}
                        {true && (
                            <TabPanel value="Camera" data-value="Camera">
                                <CameraSection 
                                    photos={enginePhotos ?? []}
                                    currentUser={currentUser}
                                    engineId={id}
                                    onDeletePhoto={deletePhoto}
                                    onPhotoAdded={getEnginePhotos}
                                />
                            </TabPanel>
                        )}

                        {/* {myEngine.features?.calendar && ( */}
                        {true && (
                            <TabPanel value="Calendar" data-value="Calendar">
                                <CalendarSection 
                                    calendarEvents={calendarEvents}
                                    currentUser={currentUser}
                                    id={id}
                                    refreshEvents={refreshEvents}
                                />
                            </TabPanel>
                        )}

                        {/* {myEngine.features?.chat && ( */}
                        {true && (
                            <TabPanel value="Chat" data-value="Chat">
                                {/* <ChatSectionContent 
                                    chatMessages={chatMessages}
                                    engineId={id}
                                    onMessageSent={getEngineChatMessages}
                                /> */}

                                {/* <Recommengine currentUser={currentUser} engine={myEngine} sendMessage={sendMessage} chatMessages={chatMessages} /> */}
                                <Recommengine id={id} currentUser={currentUser} engine={myEngine} chatMessages={chatMessages} />
                            </TabPanel>
                        )}
                    </TabsBody>

                    <TabsHeader className="bg-military-green-50 fixed bottom-0 left-0 right-0 z-50 md:hidden">
                        {tabItems}
                    </TabsHeader>
                </Tabs>
            </div>
        </div>
    );
} 