import React, { useState } from 'react';
import { Typography, Button, Dialog, DialogHeader, DialogBody, DialogFooter } from "@material-tailwind/react";

const EngineCard = ({ engine, maxActivity, getDaysAgo, onArchive }) => {
    const [openDialog, setOpenDialog] = useState(false);
    
    const handleOpen = (e) => {
        e.preventDefault(); // Prevent card click/navigation
        setOpenDialog(true);
    };

    const handleArchive = async (e) => {
        e.preventDefault();
        await onArchive(engine.id);
        setOpenDialog(false);
    };

    const getTotalActivity = () => {
        return (engine.positionsCount || 0) + 
               (engine.images?.length || 0) + 
               (engine.calendarEventsCount || 0) + 
               (engine.messageCount || 0);
    };

    const getUtilizationPercentage = () => {
        const total = getTotalActivity();
        return Math.round((total / maxActivity) * 100);
    };

    const renderUtilizationIcon = (percentage) => {
        if (percentage > 66) {
            return <i className="fas fa-check-circle text-green-600" />;
        }
        if (percentage > 33) {
            return <i className="fas fa-exclamation-circle text-yellow-600" />;
        }
        return <i className="fas fa-exclamation-triangle text-red-600" />;
    };

    const getUtilizationColor = (percentage) => {
        if (percentage > 66) return 'fill-green-600';
        if (percentage > 33) return 'fill-yellow-600';
        return 'fill-red-600';
    };

    const percentage = getUtilizationPercentage();

    return (
        <>
            <a 
                href={'/engines/' + engine.id}
                className="flex flex-col bg-white p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow cursor-pointer"
            >
                <div className="flex flex-col space-y-2">
                    <div className="flex items-center space-x-4">
                        <div 
                            className="w-8 h-8 rounded-full flex-shrink-0" 
                            style={{ backgroundColor: engine.color || '#000000' }}
                        />
                        <div>
                            <div className="flex items-center justify-between">
                                <Typography variant="small" color="blue-gray" className="font-bold text-base">
                                    {engine.name}
                                </Typography>
                                <Button 
                                    size="sm" 
                                    color="gray" 
                                    variant="text" 
                                    className="p-2"
                                    onClick={handleOpen}
                                >
                                    Archive
                                </Button>
                            </div>
                            <Typography variant="small" color="gray" className="text-sm">
                                {engine.createdAt 
                                    ? `Created ${getDaysAgo(engine.createdAt)}`
                                    : 'Creation date unknown'}
                            </Typography>
                            <div className="grid grid-cols-2 sm:grid-cols-4 sm:flex sm:space-x-4 gap-y-1 gap-x-4 mt-2">
                                <MetricItem color="blue" value={engine.positionsCount} label="locations" />
                                <MetricItem color="green" value={engine.images?.length} label="photos" />
                                <MetricItem color="orange" value={engine.calendarEventsCount} label="events" />
                                <MetricItem color="purple" value={engine.messageCount} label="messages" />
                                <div className="flex items-center gap-1 col-span-2 sm:col-span-1">
                                    <svg className="w-2 h-2" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="4" cy="4" r="4" className={getUtilizationColor(percentage)} />
                                    </svg>
                                    <Typography variant="small" color="gray" className="text-xs sm:text-sm whitespace-nowrap flex items-center gap-1">
                                        {renderUtilizationIcon(percentage)}
                                        <span className="text-gray-900">{percentage}% utilization</span>
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>

            <Dialog open={openDialog} handler={() => setOpenDialog(false)}>
                <DialogHeader>Archive Engine</DialogHeader>
                <DialogBody>
                    Are you sure you want to archive {engine.name}? This will hide it from the main view.
                </DialogBody>
                <DialogFooter>
                    <Button 
                        variant="text" 
                        color="gray" 
                        onClick={() => setOpenDialog(false)} 
                        className="mr-1"
                    >
                        Cancel
                    </Button>
                    <Button 
                        variant="gradient" 
                        color="red" 
                        onClick={handleArchive}
                    >
                        Archive
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    );
};

const MetricItem = ({ color, value, label }) => (
    <div className="flex items-center gap-1">
        <div className={`w-2 h-2 rounded-full bg-${color}-500`}/>
        <Typography variant="small" color="gray" className="text-xs sm:text-sm whitespace-nowrap">
            {value || 0} {label}
        </Typography>
    </div>
);

export default EngineCard; 