import React, { useState } from 'react';
import General from '../layouts/General';

function QAItem({ question, children }) {
  const [isExpanded, setIsExpanded] = useState(false);
  
  return (
    <div className="border rounded-lg p-3">
      <button
        className="w-full text-left flex justify-between items-center"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <h3 className="font-medium text-sm sm:text-base">{question}</h3>
        <svg
          className={`w-5 h-5 transform transition-transform ${isExpanded ? 'rotate-180' : ''}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>
      {isExpanded && (
        <div className="mt-2">
          {children}
        </div>
      )}
    </div>
  );
}

function Help() {
  return (
    <General title="Help">
      <div className="bg-white rounded-lg shadow p-3 sm:p-6">
        <h1 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6">Help & FAQ</h1>
        
        <div className="space-y-6 sm:space-y-8">
          <section>
            <h2 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4">Agents</h2>
            <div className="space-y-3">
              <QAItem question="What are Agents?">
                <p className="text-gray-600 text-sm sm:text-base">
                  Agents are AI-powered assistants that can help automate tasks and workflows in your business. Each agent can be configured for specific purposes and responsibilities.
                </p>
              </QAItem>
              <QAItem question="How do I manage my Agents?">
                <p className="text-gray-600 text-sm sm:text-base">
                  You can view all your active agents in the Agents dashboard. Here you can:
                </p>
                <div className="text-gray-600 text-sm sm:text-base space-y-2 mt-2">
                  <div>• Create new agents</div>
                  <div>• Monitor agent status</div>
                  <div>• Configure agent settings</div>
                  <div>• Archive unused agents</div>
                </div>
              </QAItem>
            </div>
          </section>

          <section>
            <h2 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4">Workflows</h2>
            <div className="space-y-3">
              <QAItem question="What are Workflows?">
                <p className="text-gray-600 text-sm sm:text-base">
                  Workflows are automated sequences of tasks that help streamline your business processes. They can involve multiple agents and steps to complete specific business objectives.
                </p>
              </QAItem>
              <QAItem question="How do I create and manage Workflows?">
                <div className="text-gray-600 text-sm sm:text-base space-y-2">
                  <div>1. Navigate to the Workflows dashboard</div>
                  <div>2. Click "Create New Workflow"</div>
                  <div>3. Define the workflow steps and conditions</div>
                  <div>4. Assign agents to specific tasks</div>
                  <div>5. Activate and monitor your workflow</div>
                </div>
              </QAItem>
            </div>
          </section>

          <section>
            <h2 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4">Engine Creation</h2>
            <div className="space-y-3">
              <QAItem question="How do I create a new engine?">
                <p className="text-gray-600 text-sm sm:text-base">Click the "Create New Engine" button and follow the 4-step process:</p>
                <div className="text-gray-600 text-sm sm:text-base space-y-2 mt-2">
                  <div>1. Name your engine</div>
                  <div>2. Define its purpose</div>
                  <div>3. Choose features or a template</div>
                  <div>4. Review and confirm</div>
                </div>
              </QAItem>
              
              <QAItem question="What features are available?">
                <div className="text-gray-600 text-sm sm:text-base space-y-2">
                  <div>• Maps for location tracking</div>
                  <div>• Chat for messaging</div>
                  <div>• Camera for photo galleries</div>
                  <div>• Calendar for scheduling</div>
                </div>
              </QAItem>
            </div>
          </section>

          <section>
            <h2 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4">Customer Management</h2>
            <div className="space-y-3 sm:space-y-4">
              <QAItem question="How do I add new customers?">
                <p className="text-gray-600 text-sm sm:text-base">Use the "Add New Customer" button to create customer profiles with contact information, location, and status.</p>
              </QAItem>
              <QAItem question="Can I edit or archive customers?">
                <p className="text-gray-600 text-sm sm:text-base">Yes, each customer card has "View" and "Edit" options. You can also archive customers to maintain their records without keeping them active.</p>
              </QAItem>
            </div>
          </section>

          <section>
            <h2 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4">Invoices & Billing</h2>
            <div className="space-y-3 sm:space-y-4">
              <QAItem question="How do I create and track invoices?">
                <p className="text-gray-600 text-sm sm:text-base">Click "Add New Invoice" to create invoices. Each invoice shows the customer name, amount, date, and payment status (Paid, Pending, or Overdue).</p>
              </QAItem>
              <QAItem question="What do the different invoice statuses mean?">
                <div className="text-gray-600 text-sm sm:text-base space-y-2">
                  <div>• Green: Paid - Payment received</div>
                  <div>• Yellow: Pending - Awaiting payment</div>
                  <div>• Red: Overdue - Payment past due</div>
                </div>
              </QAItem>
            </div>
          </section>

          <section>
            <h2 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4">Service Requests</h2>
            <div className="space-y-3 sm:space-y-4">
              <QAItem question="How do I manage service requests?">
                <p className="text-gray-600 text-sm sm:text-base">Create new requests using the "Add New Request" button. Each request includes a unique number, customer details, service type, and priority level.</p>
              </QAItem>
              <QAItem question="What do the priority levels mean?">
                <div className="text-gray-600 text-sm sm:text-base space-y-2">
                  <div>• Red: High priority - Urgent attention needed</div>
                  <div>• Yellow: Medium priority - Standard timeline</div>
                  <div>• Green: Low priority - Can be scheduled flexibly</div>
                </div>
              </QAItem>
            </div>
          </section>

          <section className="border-t pt-4 sm:pt-6">
            <p className="text-gray-500 text-sm sm:text-base">
              Need additional help? Contact our support team at <a href="mailto:brian@bcpsolutionsllc.com" className="text-blue-600 hover:underline">brian@bcpsolutionsllc.com</a>
            </p>
          </section>
        </div>
      </div>
    </General>
  );
}

export default Help; 