import React, { useState, useEffect } from 'react'
import {
    Typography,
    Input,
    Card,
} from "@material-tailwind/react";
import { EngineOverview } from './EngineOverview';


import RegularButton from './buttons/RegularButton';
import EngineCard from './EngineCard';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../FirebaseConfig';

const getDaysAgo = (createdAt) => {
    if (!createdAt) return 'Creation date unknown';
    
    let created;
    if (createdAt.seconds) {
        // Handle Firestore timestamp
        created = new Date(createdAt.seconds * 1000);
    } else if (typeof createdAt === 'string') {
        // Handle ISO date string
        created = new Date(createdAt);
    } else {
        return 'Creation date unknown';
    }
    
    const now = new Date();
    const diffTime = Math.abs(now - created);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays === 1 ? '1 day ago' : `${diffDays} days ago`;
};

export default function EngineContent({ currentUser, engines, onArchive }) {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredEngines = engines ? engines.filter(engine =>
        engine && engine.name && engine.name.toLowerCase().includes(searchTerm.toLowerCase())
    ).sort((a, b) => {
        // Calculate total activity for each engine
        const getTotalActivity = (engine) => {
            return (engine.positionsCount || 0) + 
                   (engine.images?.length || 0) + 
                   (engine.calendarEventsCount || 0) + 
                   (engine.messageCount || 0);
        };
        
        return getTotalActivity(b) - getTotalActivity(a); // Sort in descending order
    }) : [];

    useEffect(() => {
        console.log('Engines data:', engines);
    }, [engines]);

    // Calculate maximum activity across all engines
    const maxActivity = engines?.reduce((max, engine) => {
        const total = (engine.positionsCount || 0) + 
                     (engine.images?.length || 0) + 
                     (engine.calendarEventsCount || 0) + 
                     (engine.messageCount || 0);
        return Math.max(max, total);
    }, 0) || 1; // Default to 1 to avoid division by zero

    const handleArchiveEngine = async (engineId) => {
        try {
            const engineRef = doc(db, 'recommengine', 'users', currentUser.uid, 'data', 'engines', engineId);
            await updateDoc(engineRef, {
                archived: true,
                archivedAt: new Date()
            });
            
            console.log('Engine archived successfully');
            // Notify parent component to refresh the engines list
            if (onArchive) {
                onArchive(engineId);
            }
        } catch (error) {
            console.error('Failed to archive engine:', error);
        }
    };

    return (
        <div className='flex flex-col mt-2'>
            {/* <RegularButton filled={true} text="Create New Engine" url="/engines/new" /> */}
            {engines && engines.length > 0 && (
                <div className="mb-4 mt-4">
                    <Input
                        type="text"
                        label="Search engines"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
            )}
            <div className="h-full w-full">
                <div className="grid grid-cols-1 gap-4 p-4">
                    {filteredEngines.length === 0 && (
                        <div className="flex flex-col justify-center items-center h-full">
                            <EngineOverview />
                            <RegularButton filled={true} text="Create New Engine" url="/engines/new" />
                        </div>
                    )}
                    {filteredEngines.map((engine, index) => (
                        <EngineCard
                            key={`engine-${engine.id}+${index}`}
                            engine={engine}
                            maxActivity={maxActivity}
                            getDaysAgo={getDaysAgo}
                            onArchive={handleArchiveEngine}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}
