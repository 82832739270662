import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { Typography, Input, Button, Dialog } from "@material-tailwind/react";
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
  InputToolbox,
  AttachmentButton,
} from '@chatscope/chat-ui-kit-react';
import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from '../FirebaseConfig';
import SpeechControl from './engines/SpeechControl';

const ChatSidebar = ({ isOpen, onClose, currentUser, systemMessage, sendMessage, chatMessages, activeAgent, currentEngine, currentAgent, isCompact = false }) => {
  const [inputText, setInputText] = useState('');
  const [apiKey, setApiKey] = useState(null);
  const [useOpenAI, setUseOpenAI] = useState(false);
  const [engine, setEngine] = useState(currentEngine);
  const [enableAi, setEnableAi] = useState(() => {
    return localStorage.getItem('chatEnableAi') !== 'false';
  });

  const [agent, setAgent] = useState(activeAgent);

  // console.log('agent: ', agent);

  useEffect(() => {
    if (activeAgent) {
      setAgent(activeAgent);
      console.log('activeAgent: ', activeAgent);
      // alert('activeAgent: ', activeAgent);
    }
  }, [activeAgent]);

  useEffect(() => {
    if (currentEngine) {
      setEngine(currentEngine);
      console.log('currentEngine: ', currentEngine);
    } else {
      console.log('currentEngine not set: ', currentEngine);
    }
  }, [currentEngine]);

  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);

  const [messageCount, setMessageCount] = useState(() => {
    return parseInt(localStorage.getItem('chatMessageCount')) || 0;
  });

  const [lastResetDate, setLastResetDate] = useState(() => {
    return localStorage.getItem('chatLastResetDate') || new Date().toDateString();
  });

  const [latestResponse, setLatestResponse] = useState(null);

  const MESSAGE_LIMIT = 1000;

  const [autoSpeak, setAutoSpeak] = useState(() => {
    return localStorage.getItem('chatAutoSpeak') === 'true';
  });

  const [spokenMessages, setSpokenMessages] = useState(new Set());

  const [isNewResponse, setIsNewResponse] = useState(false);

  const [useRecommengine, setUseRecommengine] = useState(() => {
    return localStorage.getItem('useRecommengine') === 'true' || true;
  });

  const messageListRef = useRef(null);
  const scrollContainerRef = useRef(null);

  const [animatingMessage, setAnimatingMessage] = useState(null);
  const [displayedText, setDisplayedText] = useState('');

  const scrollToBottom = useCallback(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }
  }, []);

  useEffect(() => {
    // Initial scroll to bottom
    scrollToBottom();
    // Add a slight delay to ensure content is rendered
    setTimeout(scrollToBottom, 100);
  }, []);

  const animateText = useCallback(async (text) => {
    setAnimatingMessage(text);
    setDisplayedText('');
    
    for (let i = 0; i < text.length; i++) {
      await new Promise(resolve => setTimeout(resolve, 20)); // Adjust speed here
      setDisplayedText(prev => prev + text[i]);
      scrollToBottom();
    }
    
    setAnimatingMessage(null);
  }, [scrollToBottom]);

  useEffect(() => {
    if (chatMessages) {
      setMessages((prevMessages) => {
        const existingMessages = new Map(
          prevMessages.map(msg => [
            `${msg.sender}-${msg.sentTime}-${msg.message}`,
            msg
          ])
        );

        const newMessages = formatChatMessages(chatMessages).filter(msg => {
          const messageId = `${msg.sender}-${msg.sentTime}-${msg.message}`;
          const isDuplicate = existingMessages.has(messageId);
          if (!isDuplicate) {
            existingMessages.set(messageId, msg);
          }
          return !isDuplicate;
        });

        if (newMessages.length > 0) {
          scrollToBottom();
        }
        return [...prevMessages, ...newMessages];
      });
    }
  }, [chatMessages, scrollToBottom]);

  useEffect(() => {
    if (agent) {
      setMessages([
        {
          message: `Hey there! My agent is ${agent.name}, and they are my agent with the role of ${agent.role || agent.description}. Can you help me?`,
          sentTime: "just now",
          sender: agent.name,
          direction: "outgoing",
          sender: currentUser.uid,
        },
      ]);
    } else if (engine) {
      setMessages([
        {
          message: `Hey there! My agent is ${engine.name}, and they are my agent with the purpose of ${engine.purpose}. Can you help me?`,
          sentTime: "just now",
          sender: engine.name,
          direction: "outgoing",
          sender: currentUser.uid,
        },
      ]);
    } else {
      setMessages([
        {
          message: `Hey there! My agent is Rico, and they are my agent with the purpose of being your business companion. Can you help me?`,
          sentTime: "just now",
          sender: "Rico",
          direction: "outgoing",
          sender: currentUser.uid,
        },
      ]);
    }
  }, [agent, engine]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  const formatChatMessages = (chatMessages) => {
    return chatMessages.map((message) => {
      return {
        message: message.message,
        sender: message.sender,
        sentTime: message.sentTime,
        email: message.email,
      };
    });
  };

  const fetchApiKey = useCallback(async () => {
    try {
      const functions = getFunctions(app);
      const getOpenAIConfig = httpsCallable(functions, 'getOpenAIConfig');
      const result = await getOpenAIConfig();
      if (result?.data?.apiKey) {
        setApiKey(result.data.apiKey);
      }
    } catch (error) {
      console.error("Error fetching OpenAI config:", error);
    }
  }, []);

  useEffect(() => {
    if (!apiKey && useOpenAI) {
      fetchApiKey();
    }
  }, []);

  const checkAndResetDaily = () => {
    const today = new Date().toDateString();
    if (today !== lastResetDate) {
      setMessageCount(0);
      setLastResetDate(today);
      localStorage.setItem('chatMessageCount', '0');
      localStorage.setItem('chatLastResetDate', today);
    }
  };

  const handleSendRequest = async (message) => {
    // if (!enableAi) {
    //   setMessages(prev => [...prev, {
    //     message: "AI responses are currently disabled. Enable AI to chat with Rico.",
    //     sender: "Rico",
    //   }]);
    //   return;
    // }

    console.log('handleSendRequest triggered with message:', message); // Debug log

    if (!apiKey && useOpenAI) {
      setMessages(prev => [...prev, {
        message: "Chat service is currently unavailable. Please try again later.",
        sender: agent?.name || "Rico",
      }]);
      return;
    }

    checkAndResetDaily();
    if (messageCount >= MESSAGE_LIMIT) {
      setMessages(prev => [...prev, {
        message: `You've reached your daily message limit of ${MESSAGE_LIMIT}. Please try again tomorrow.`,
        sender: agent?.name || "Rico",
      }]);
      return;
    }

    const newMessage = {
      message,
      direction: 'outgoing',
      sender: currentUser.uid,
      email: currentUser.email,
      sentTime: new Date().toISOString(),
    };

    const newCount = messageCount + 1;
    setMessageCount(newCount);
    localStorage.setItem('chatMessageCount', newCount.toString());
    setMessages((prevMessages) => {
      scrollToBottom();
      return [...prevMessages, newMessage];
    });

    // sendMessage(newMessage);

    if (enableAi) {
      setIsTyping(true);
      try {
        console.log('Processing message with useRecommengine:', useRecommengine); // Debug log
        const response = await processMessage([...messages, newMessage]);
        console.log('Response from processMessage:', response); // Debug log
        if (response && response.choices && response.choices.length > 0) {
          const content = response.choices[0]?.message?.content;
          if (content) {
            const chatGPTResponse = {
              message: content,
              sender: "Rico",
            };
            setMessages((prevMessages) => [...prevMessages, chatGPTResponse]);
            setLatestResponse(content);
            setIsNewResponse(true);
            await animateText(content);
          }
        }
      } catch (error) {
        console.error("Error processing message:", error);
      } finally {
        setIsTyping(false);
      }
    }
  };

  const processMessage = async (chatMessages) => {
    if (!apiKey && useOpenAI) {
      throw new Error("API key is not available");
    }
    
    console.log('Processing message with useRecommengine:', useRecommengine);
    
    // const systemMessage = {
    //   role: "system",
    //   content: agent 
    //     ? `I'm ${agent.name}, ${agent.role || agent.description}`
    //     : engine
    //       ? `I'm ${engine.name}, ${engine.purpose}`
    //       : "I'm a business owner using ChatGPT to organize my business"
    // };
    
    const apiMessages = chatMessages.map((messageObject) => {
      const role = messageObject.sender === (agent?.name || "Rico") ? "assistant" : "user";
      return { role, content: messageObject.message };
    });

    if (useRecommengine) {
      const recommenginePayload = {
        data: {
          messages: [
            ...apiMessages
          ],
          agent: agent?.id || engine?.id || 'rico',
          agent_id: activeAgent?.id || agent?.id || null,
          agent_name: activeAgent?.name || agent?.name || null,
          agent_purpose: activeAgent?.role || agent?.role || null,
          engine_id: engine?.id || null,
          engine_name: engine?.name || null,
          engine_purpose: engine?.purpose || null
        },
        uid: currentUser.uid,
        // engine_id: engine?.id || 'rico',
        // engine_name: engine?.name || 'Rico',
        // engine_purpose: engine?.purpose || 'business companion',
      };

      try {
        const response = await fetch("https://jn3m0kb9f2.execute-api.us-east-1.amazonaws.com/Production/chat", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          mode: 'cors',
          body: JSON.stringify(recommenginePayload),
        });

        if (!response.ok) {
          const errorMessage = "An error occurred while processing your request. Please try again later.";
          setErrorMessage(errorMessage);
          setShowErrorDialog(true);
          setMessages(prev => [...prev, {
            message: errorMessage,
            sender: agent?.name || "Rico",
            error: true
          }]);
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        // Extract text from content array if it exists
        const messageText = data.content?.[0]?.text || data.message || data.content || "No response content";
        
        return {
          choices: [{
            message: {
              content: messageText
            }
          }]
        };
      } catch (error) {
        console.error("Error calling Recommengine API:", error);
        throw error;
      }
    } else {
      console.log('Entering OpenAI flow'); // Debug log

      // const apiRequestBody = {
      //   "model": "gpt-3.5-turbo",
      //   "messages": [
      //     systemMessage,
      //     ...apiMessages
      //   ],
      // };

      // try {
      //   const response = await fetch("https://api.openai.com/v1/chat/completions", {
      //     method: "POST",
      //     headers: {
      //       "Authorization": "Bearer " + apiKey,
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify(apiRequestBody),
      //   });

      //   if (!response.ok) {
      //     throw new Error(`HTTP error! status: ${response.status}`);
      //   }
        
      //   return await response.json();
      // } catch (error) {
      //   console.error("Error calling OpenAI API:", error);
      //   throw error;
      // }
    }
  };

  // const getLastRicoMessage = useMemo(() => {
  //   return messages.filter(m => m.sender === "Rico").slice(-1)[0]?.message || null;
  // }, [messages]);

  useEffect(() => {
    console.log('Auto-speak effect triggered:', {
      autoSpeak,
      latestResponse,
      isNewResponse,
      isInSpokenMessages: spokenMessages.has(latestResponse)
    });

    if (autoSpeak && latestResponse && isNewResponse && !spokenMessages.has(latestResponse)) {
      const lastMessage = messages.filter(m => m.sender === "Rico").slice(-1)[0];
      console.log('Checking last message:', {
        lastMessage,
        latestResponse,
        isMatch: lastMessage?.message === latestResponse
      });

      if (lastMessage?.message === latestResponse) {
        console.log('Auto-speak conditions met, preparing to speak');
        setSpokenMessages(prev => new Set(prev).add(latestResponse));
        setIsNewResponse(false);
      }
    }
  }, [autoSpeak, latestResponse, spokenMessages, isNewResponse]);

  const renderMessages = useCallback((messages) => {
    return messages.map((message, i) => {
      const isNewMessage = message.message === latestResponse && isNewResponse;
      const senderName = message.sender === (agent?.name || "Rico") ? (agent?.name || "Rico") : message.email || "User";
      
      const displayMessage = 
        animatingMessage && message.message === animatingMessage ? 
        displayedText : 
        message.message;

      return (
        <Message 
          key={i}
          model={{
            ...message,
            message: displayMessage,
            direction: (message.sender === (agent?.name || "Rico") || message.sender !== currentUser.uid) ? "incoming" : "outgoing",
            position: "normal"
          }}
        >
          <Message.Header sender={senderName} className="text-white" />
          <Message.Footer className="flex items-center gap-4">
            <SpeechControl 
              text={displayMessage}
              currentUser={currentUser}
              engineId={agent?.id?.toLowerCase() || "rico"}
              agentId={activeAgent ? activeAgent?.id : "rico"}
              data-message={displayMessage}
              onRef={autoSpeak && isNewMessage ? true : false}
            />
            <div className="flex gap-2">
              {message.error ? (
                <button 
                  onClick={() => handleSendRequest(message.message)}
                  className="text-xs px-2 py-1 hover:bg-gray-100 rounded text-red-500"
                >
                  Retry
                </button>
              ) : (
                <>
                  <button 
                    onClick={() => handleCalendarAction(message)}
                    className="text-xs px-2 py-1 hover:bg-gray-100 rounded"
                  >
                    Calendar
                  </button>
                  <button 
                    onClick={() => handleCameraAction(message)}
                    className="text-xs px-2 py-1 hover:bg-gray-100 rounded"
                  >
                    Camera
                  </button>
                  <button 
                    onClick={() => handleMapAction(message)}
                    className="text-xs px-2 py-1 hover:bg-gray-100 rounded"
                  >
                    Map
                  </button>
                </>
              )}
            </div>
          </Message.Footer>
        </Message>
      );
    });
  }, [currentUser, latestResponse, autoSpeak, isNewResponse, agent, animatingMessage, displayedText]);

  const typingIndicator = useMemo(() => (
    isTyping ? <TypingIndicator content={`${agent?.name || 'Rico'} is typing`} /> : null
  ), [isTyping, agent]);

  const handleAutoSpeakChange = (e) => {
    const isChecked = e.target.checked;
    setAutoSpeak(isChecked);
    localStorage.setItem('chatAutoSpeak', isChecked);
  };

  const handleAiToggle = (e) => {
    const isChecked = e.target.checked;
    setEnableAi(isChecked);
    localStorage.setItem('chatEnableAi', isChecked);
  };

  useEffect(() => {
    if (systemMessage) {
      setMessages(prevMessages => [...prevMessages, {
        message: systemMessage,
        sender: "Rico",
        sentTime: "just now"
      }]);
    }
  }, [systemMessage]);

  const handleFileUpload = (event) => {
    console.log('handleFileUpload triggered', event.target.files);
    const file = event.target.files[0];
    if (file) {
      console.log('upload the file to google cloud storage')
      // handleSendRequest(`I've uploaded a file: ${file.name}`);
    }
  };

  const handleAttachmentClick = useCallback((event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    console.log('handleAttachmentClick triggered');
    
    const input = document.createElement('input');
    input.type = 'file';
    input.style.display = 'none';
    document.body.appendChild(input);
    
    input.onchange = (e) => {
      handleFileUpload(e);
      document.body.removeChild(input);
    };
    
    input.click();
  }, []);

  const handleApiToggle = (e) => {
    const isChecked = e.target.checked;
    setUseRecommengine(isChecked);
    localStorage.setItem('useRecommengine', isChecked);
  };

  useEffect(() => {
    console.log('ChatSidebar rendered');
    return () => console.log('ChatSidebar unmounted');
  }, []);

  const handleCalendarAction = (message) => {
    console.log('Calendar action for message:', message);
    setShowDialog(true);
  };

  const handleCameraAction = (message) => {
    console.log('Camera action for message:', message);
    setShowCameraDialog(true);
  };

  const handleMapAction = (message) => {
    console.log('Map action for message:', message);
    setShowMapDialog(true);
  };

  const [showDialog, setShowDialog] = useState(false);
  const [showCameraDialog, setShowCameraDialog] = useState(false);
  const [showMapDialog, setShowMapDialog] = useState(false);

  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [showOptions, setShowOptions] = useState(false);

  return (
    <div className={`
      flex flex-col w-full bg-white z-[1001] overflow-hidden
      ${isCompact 
        ? 'h-[40vh]'
        : 'h-[65vh] md:h-[40vh] lg:h-[70vh]'}
    `}>
      <div className="p-4">
        {/* Mobile Options Toggle - Show in compact mode or on mobile */}
        <div className={`${!isCompact && 'md:hidden'}`}>
          <button 
            className="w-full flex items-center justify-between px-3 py-2 text-sm text-gray-600 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
            onClick={() => setShowOptions(!showOptions)}
          >
            <span>Chat Options</span>
            <svg 
              className={`w-4 h-4 transition-transform ${showOptions ? 'rotate-180' : ''}`}
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </button>
        </div>

        {/* Options Container */}
        <div className={`
          flex flex-col md:flex-row items-start md:items-center gap-2 md:gap-4 w-full
          ${(!isCompact && 'md:flex')} ${showOptions ? 'flex mt-3' : 'hidden'}
        `}>
          <div className="flex items-center w-full md:w-auto">
            <input
              type="checkbox"
              id="enableAi"
              checked={enableAi}
              onChange={handleAiToggle}
              className="mr-2"
            />
            <label htmlFor="enableAi" className="text-sm text-gray-600">
              Enable AI responses
            </label>
          </div>
          <div className="flex items-center w-full md:w-auto">
            <input
              type="checkbox"
              id="autoSpeak"
              checked={autoSpeak}
              onChange={handleAutoSpeakChange}
              className="mr-2"
            />
            <label htmlFor="autoSpeak" className="text-sm text-gray-600">
              Auto-speak responses
            </label>
          </div>
          <div className="flex items-center w-full md:w-auto">
            <input
              type="checkbox"
              id="useRecommengine"
              checked={useRecommengine}
              onChange={handleApiToggle}
              className="mr-2"
            />
            <label htmlFor="useRecommengine" className="text-sm text-gray-600">
              Use Recommengine
            </label>
          </div>
        </div>
      </div>

      <MainContainer className="flex-1 overflow-hidden">
        <ChatContainer className="flex-1">
          <MessageList 
            scrollBehavior="smooth" 
            typingIndicator={typingIndicator}
            className="pb-16 h-full"
            ref={messageListRef}
          >
            <div ref={scrollContainerRef} className="cs-message-list__scroll-wrapper">
              {renderMessages(messages)}
            </div>
          </MessageList>
          <MessageInput 
            placeholder="Send a Message" 
            onSend={handleSendRequest}
            attachButton={true}
            onAttachClick={handleAttachmentClick}
          >
            <InputToolbox>
              <AttachmentButton
                onClick={handleAttachmentClick}
                tooltip="Attach a file"
                disabled={false}
              />
            </InputToolbox>
          </MessageInput>
        </ChatContainer>
      </MainContainer>

      <Dialog open={showDialog} handler={() => setShowDialog(false)}>
        <div className="p-6">
          <Typography variant="h5" className="mb-2">Coming Soon</Typography>
          <Typography variant="paragraph">
            Calendar functionality will be available in a future update.
          </Typography>
          <Button
            variant="text"
            color="blue-gray"
            onClick={() => setShowDialog(false)}
            className="mt-4"
          >
            Close
          </Button>
        </div>
      </Dialog>

      <Dialog open={showCameraDialog} handler={() => setShowCameraDialog(false)}>
        <div className="p-6">
          <Typography variant="h5" className="mb-2">Coming Soon</Typography>
          <Typography variant="paragraph">
            Camera functionality will be available in a future update.
          </Typography>
          <Button
            variant="text"
            color="blue-gray"
            onClick={() => setShowCameraDialog(false)}
            className="mt-4"
          >
            Close
          </Button>
        </div>
      </Dialog>

      <Dialog open={showMapDialog} handler={() => setShowMapDialog(false)}>
        <div className="p-6">
          <Typography variant="h5" className="mb-2">Coming Soon</Typography>
          <Typography variant="paragraph">
            Map functionality will be available in a future update.
          </Typography>
          <Button
            variant="text"
            color="blue-gray"
            onClick={() => setShowMapDialog(false)}
            className="mt-4"
          >
            Close
          </Button>
        </div>
      </Dialog>

      <Dialog open={showErrorDialog} handler={() => setShowErrorDialog(false)}>
        <div className="p-6">
          <Typography variant="h5" className="mb-2">Error</Typography>
          <Typography variant="paragraph" color="red">
            {errorMessage}
          </Typography>
          <Button
            variant="text"
            color="blue-gray"
            onClick={() => setShowErrorDialog(false)}
            className="mt-4"
          >
            Close
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default React.memo(ChatSidebar);
