import React from 'react';
import { Typography, Button } from "@material-tailwind/react";

export function ComposableAppsSection({ scrollToForm }) {
  return (
    <section className="bg-white py-16 border-b border-military-green-200">
      <div className='max-w-7xl mx-auto px-4'>
        {/* <Typography variant='h2' className='text-center mb-12 text-3xl font-bold'>
          <span className="bg-gradient-to-r from-military-green-600 to-slate-blue-500 bg-clip-text text-transparent">
            Discover More Benefits & Features
          </span>
        </Typography> */}
        
        {/* Top row cards */}
        <div className="grid md:grid-cols-3 gap-8 mb-12">
          <div className="p-6 rounded-lg border border-military-green-200 hover:shadow-lg transition-shadow">
            <h3 className="text-xl font-semibold mb-3 text-military-green-600">Modular Architecture</h3>
            <p className="text-gray-600">Build your engine piece by piece with our plug-and-play modules. Choose only what you need.</p>
          </div>
          <div className="p-6 rounded-lg border border-military-green-200 hover:shadow-lg transition-shadow">
            <h3 className="text-xl font-semibold mb-3 text-military-green-600">Scalable Performance</h3>
            <p className="text-gray-600">Designed to handle growing workloads with efficient resource management and optimization.</p>
          </div>
          <div className="p-6 rounded-lg border border-military-green-200 hover:shadow-lg transition-shadow">
            <h3 className="text-xl font-semibold mb-3 text-military-green-600">Easy Integration</h3>
            <p className="text-gray-600">Seamlessly integrate with existing systems using our comprehensive API and documentation.</p>
          </div>
        </div>

        {/* Benefits cards */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="p-6 rounded-lg border border-military-green-200 hover:shadow-lg transition-shadow">
            <h3 className="text-xl font-semibold mb-3 text-military-green-600">Custom Workflows</h3>
            <p className="text-gray-600">Design and implement your own workflows to match your specific business needs.</p>
          </div>
          <div className="p-6 rounded-lg border border-military-green-200 hover:shadow-lg transition-shadow">
            <h3 className="text-xl font-semibold mb-3 text-military-green-600">Real-time Analytics</h3>
            <p className="text-gray-600">Monitor and analyze your engine's performance with built-in analytics tools.</p>
          </div>
          <div className="p-6 rounded-lg border border-military-green-200 hover:shadow-lg transition-shadow">
            <h3 className="text-xl font-semibold mb-3 text-military-green-600">Security First</h3>
            <p className="text-gray-600">Enterprise-grade security features to protect your data and applications.</p>
          </div>
        </div>

        <div className="mt-12 flex flex-col items-center space-y-6">
          <Typography className="text-military-green-600 max-w-2xl mx-auto text-center">
            From solo entrepreneurs to enterprise teams, your engine's architecture scales seamlessly. Start with core modules and expand your stack as you grow.
          </Typography>
          <Button 
            onClick={() => scrollToForm('register')}
            className="w-full sm:w-64 md:w-96 px-8 py-4 bg-gradient-to-r from-military-green-500 to-slate-blue-500 text-white font-semibold rounded-lg hover:opacity-90 transition-all duration-500 shadow-lg hover:shadow-xl flex items-center justify-center gap-2"
            size="lg"
          >
            Build Your First Engine
            <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
            </svg>
          </Button>
        </div>
      </div>
    </section>
  );
} 