import React, { useState, useEffect } from 'react';
import ChatSidebar from './ChatSidebar';
// import { Button } from "@material-tailwind/react";
import { Select, Option } from "@material-tailwind/react";

import { db, firebaseConfig } from '../FirebaseConfig';

import { getMyEngines } from '../services/engineService';

const Recommengine = ({ id, currentUser, engine, currentAgent, chatMessages }) => {
    const [myEngines, setMyEngines] = useState([]);
    const [currentEngine, setCurrentEngine] = useState(engine || null);
    const [loading, setLoading] = useState(true);
    const [selectedEngine, setSelectedEngine] = useState(currentEngine?.id || '');
    const [systemMessage, setSystemMessage] = useState('');

    const handleUploadFile = () => {
        // Logic for uploading a file
        console.log("Upload file button clicked");
    };

    const handleGetCurrentLocation = () => {
        // Logic for getting current location
        console.log("Get current location button clicked");
    };

    const handleLaunchCamera = () => {
        // Logic for launching the camera
        console.log("Launch camera button clicked");
    };

    const handleAddNewEvent = () => {
        // Logic for adding a new event
        console.log("Add new event button clicked");
    };

    useEffect(() => {
        setCurrentEngine(engine);
    }, [engine]);

    useEffect(() => {
        console.log('selectedEngine', selectedEngine);
    }, [selectedEngine]);

    const handleEngineSelect = (value) => {
        // Force a re-render by setting to empty string first
        setSelectedEngine('');
        setTimeout(() => {
            setSelectedEngine(value);
            const selectedEngineDetails = myEngines.find(engine => engine.id === value);
            if (selectedEngineDetails) {
                const systemMessage = `Switched to engine: ${selectedEngineDetails.name}`;
                setSystemMessage(systemMessage);
            }
        }, 0);
    };
    

    useEffect(() => {
        currentUser && getMyEngines(currentUser.uid).then((engines) => {
            const sortedEngines = engines.sort((a, b) => (b.messageCount || 0) - (a.messageCount || 0));
            setMyEngines(sortedEngines);
            setLoading(false);
        })
    }, [currentUser]);

    const sendMessage = async (message) => {
        console.log('sendMessage: ', message);
        // save in realtime db
        const idToken = await currentUser.getIdToken();
        const realtimeDbUrl = `${firebaseConfig.databaseURL}/recommengine/users/${currentUser.uid}/engines/${id}/messages.json?auth=${idToken}`;
        await fetch(realtimeDbUrl, {
            method: 'POST',
            body: JSON.stringify(message)
        });
    };

    return (
        <div className="flex flex-col">
            <div className="flex-1">
                {/* {myEngines && myEngines.length > 0 && (
                    <Select
                        label="Select an Engine"
                        onChange={handleEngineSelect}
                        className="mb-4"
                    >
                        {myEngines.map((engine) => (
                            <Option 
                                key={engine.id} 
                                value={engine.id}
                                selected={String(selectedEngine) === String(engine.id)}
                            >
                                {engine.name} ({engine.messageCount || 0} uses)
                            </Option>
                        ))}
                    </Select>
                )} */}
                
                <ChatSidebar 
                    isOpen={true} 
                    onClose={() => {}} 
                    currentUser={currentUser}
                    systemMessage={systemMessage}
                    sendMessage={sendMessage}
                    chatMessages={chatMessages}
                    currentEngine={currentEngine}
                    activeAgent={currentAgent}
                />
            </div>
            
            {/* <div className="flex flex-col gap-4">
                <Button onClick={handleUploadFile}>Upload File</Button>
                <Button onClick={handleGetCurrentLocation}>Get Current Location</Button>
                <Button onClick={handleLaunchCamera}>Launch Camera</Button>
                <Button onClick={handleAddNewEvent}>Add New Event</Button>
            </div> */}
        </div>
    );
};

export default Recommengine;
