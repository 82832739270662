import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useAuth } from '../../contexts/AuthContext';
import General from '../../layouts/General';
import RegularButton from '../buttons/RegularButton';
import { useParams } from 'react-router-dom';

export default function AdminUser() {
    const [userStatus, setUserStatus] = useState(null);
    const [userId, setUserId] = useState(null);
    const { id } = useParams();
    const { currentUser } = useAuth();
    
    const getUserStatus = async () => {
        const functions = getFunctions();
        const getUserStatusFn = httpsCallable(functions, 'getUserStatus');
        try {
            console.log('userId', userId);
            const result = await getUserStatusFn({ uid: userId });
            return result.data;
        } catch (error) {
            console.error('Error fetching user status:', error);
            return null;
        }
    }

    useEffect(() => {
        if (id) {
            setUserId(id);
        }
    }, [id]);

    useEffect(() => {
        if (userId) {
            getUserStatus().then((status) => {
                setUserStatus(status);
            });
        }
    }, [userId]);

    return (
        <General>
            <div className="p-4 bg-white rounded-lg shadow-sm border border-blue-gray-50">
                <RegularButton url="/admin/users" text="Back" filled={false} />
                <h2 className="text-lg font-semibold mb-4">User Status</h2>
                {userStatus ? (
                <div>
                    <p>Email Verified: {userStatus.hasVerifiedEmail ? 'Yes' : 'No'}</p>
                    <p>Created Agent: {userStatus.hasCreatedAgent ? 'Yes' : 'No'}</p>
                    <p>Created Engine: {userStatus.hasCreatedEngine ? 'Yes' : 'No'}</p>
                    <p>Sent Message: {userStatus.hasSentMessage ? 'Yes' : 'No'}</p>
                    <p>Active Subscription: {userStatus.hasActiveSubscription ? 'Yes' : 'No'}</p>
                </div>
            ) : (
                    <p>Loading user status...</p>
                )}
            </div>
        </General>
    );
}