import React, { useEffect, useState } from 'react'
import LoadingIndicator from './LoadingIndicator';

// import { Typography, Card, CardBody } from "@material-tailwind/react";
import { getFirestore, collection, query, where, getDocs, getDoc, doc } from "firebase/firestore";

// import Header from './Header'
// import { Footer } from './Footer'
// import NavigationLinks from './NavigationLinks'
import { db, auth } from '../FirebaseConfig'

import General from '../layouts/General'
import DashboardContent from './DashboardContent';

const getMyEngines = async (userId) => {
    const querySnapshot = await getDocs(
        query(
            collection(db, "recommengine/users/"+ userId +"/data/engines"),
            where("archived", "!=", true)
        )
    );

    const engines = [];

    querySnapshot.forEach((doc) => {
        engines.push(doc.data());
    });

    return engines;
}

const getMyAgents = async (userId) => {
    const querySnapshot = await getDocs(
        query(collection(db, "recommengine/users/" + userId + "/data/agents"))
    );

    const agents = [];
    querySnapshot.forEach((doc) => {
        agents.push(doc.data());
    });

    return agents;
}

// const getMyQuizzes = async (userId) => {
//     const querySnapshot = await getDocs(
//         query(collection(db, "recommengine/users/" + userId + "/data/quizzes"))
//     );

//     const quizzes = [];
//     querySnapshot.forEach((doc) => {
//         quizzes.push(doc.data());
//     });

//     return quizzes;
// }

const getMyInvoices = async (userId) => {
    const querySnapshot = await getDocs(
        query(collection(db, "recommengine/users/" + userId + "/data/invoices"))
    );

    const invoices = [];
    querySnapshot.forEach((doc) => {
        invoices.push(doc.data());
    });

    return invoices;
}

const getMyCustomers = async (userId) => {
    const querySnapshot = await getDocs(
        query(
            collection(db, "recommengine/users/" + userId + "/data/customers"),
            where("active", "==", true)
        )
    );

    const customers = [];
    querySnapshot.forEach((doc) => {
        customers.push(doc.data());
    });

    return customers;
}

const getMyRequests = async (userId) => {
    const querySnapshot = await getDocs(
        query(collection(db, "recommengine/users/" + userId + "/data/requests"))
    );

    const requests = [];
    querySnapshot.forEach((doc) => {
        requests.push(doc.data());
    });

    return requests;
}

export default function Dashboard({ currentUser }) {
    const [loading, setLoading] = useState(true);
    const [myEngines, setMyEngines] = useState([]);
    const [myAgents, setMyAgents] = useState([]);
    // const [myQuizzes, setMyQuizzes] = useState([]);
    const [myInvoices, setMyInvoices] = useState([]);
    const [myCustomers, setMyCustomers] = useState([]);
    const [myRequests, setMyRequests] = useState([]);
    const [user, setUser] = useState(null);

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if (user) {
                setUser(user);
                
                getMyEngines(user.uid).then((engines) => {
                    setMyEngines(engines);
                })
                getMyAgents(user.uid).then((agents) => {
                    setMyAgents(agents);
                })
                // getMyQuizzes(user.uid).then((quizzes) => {
                //     setMyQuizzes(quizzes);
                // })
                getMyInvoices(user.uid).then((invoices) => {
                    setMyInvoices(invoices);
                })
                getMyCustomers(user.uid).then((customers) => {
                    setMyCustomers(customers);
                })
                getMyRequests(user.uid).then((requests) => {
                    setMyRequests(requests);
                })
                setLoading(false);
            } else {
                setLoading(false);
            }
        }) 
    }, [])

    if (loading || (!currentUser && !user)) {
        return <LoadingIndicator />
    }

    const activeUser = currentUser || user;

    return (
        <General title="Dashboard">
            <DashboardContent 
                currentUser={activeUser} 
                myEngines={myEngines} 
                myAgents={myAgents}
                myInvoices={myInvoices}
                myCustomers={myCustomers}
                myRequests={myRequests}
            />
        </General>
    )
}