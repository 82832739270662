import React, { useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from '../../FirebaseConfig';
import RegularButton from '../buttons/RegularButton';
import { useNavigate } from 'react-router-dom';

const createWorkflow = async (userId, workflowData) => {
    const functions = getFunctions(app);
    const createWorkflow = httpsCallable(functions, 'createWorkflow');
    
    try {
        const result = await createWorkflow({
            uid: userId,
            ...workflowData
        });
        return result.data;
    } catch (error) {
        console.error("Error creating workflow:", error);
        return null;
    }
};

export default function WorkflowContent({ currentUser, myWorkflows, refreshWorkflows }) {
    const [isCreating, setIsCreating] = useState(false);
    const [newWorkflowName, setNewWorkflowName] = useState('');
    const navigate = useNavigate();

    const handleCreateWorkflow = async () => {
        if (!newWorkflowName.trim()) return;

        const workflowData = {
            name: newWorkflowName,
            description: "",
            steps: [],
            created: new Date().toISOString(),
            archived: false
        };

        const result = await createWorkflow(currentUser.uid, workflowData);
        if (result) {
            setNewWorkflowName('');
            setIsCreating(false);
            refreshWorkflows(currentUser.uid);
        }
    };

    return (
        <div className="container mx-auto px-4">
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold">My Workflows</h2>
                <RegularButton 
                    filled
                    text={isCreating ? "Cancel" : "Create New Workflow"} 
                    onClick={() => setIsCreating(!isCreating)}
                />
            </div>

            {isCreating && (
                <div className="mb-6 p-4 bg-white rounded-lg shadow">
                    <input
                        type="text"
                        value={newWorkflowName}
                        onChange={(e) => setNewWorkflowName(e.target.value)}
                        placeholder="Enter workflow name"
                        className="w-full p-2 border rounded mb-4"
                    />
                    <RegularButton 
                        text="Save Workflow" 
                        onClick={handleCreateWorkflow}
                    />
                </div>
            )}

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {myWorkflows.map((workflow) => (
                    <div key={workflow.id} className="bg-white p-4 rounded-lg shadow">
                        <h3 className="text-xl font-semibold mb-2">{workflow.data.name}</h3>
                        <p className="text-gray-600 text-sm">
                            {/* Created: {new Date(workflow.data.created).toLocaleDateString()} */}
                        </p>
                        <div className="mt-4 flex space-x-2">
                            <RegularButton 
                                text="Edit" 
                                onClick={() => navigate(`/workflows/edit/${workflow.id}`)}
                            />
                            {/* <RegularButton 
                                text="Delete" 
                                onClick={() => { console.log('delete')}}
                            /> */}
                        </div>
                    </div>
                ))}
            </div>

            {myWorkflows.length === 0 && !isCreating && (
                <div className="text-center text-gray-500 mt-8">
                    No workflows yet. Create your first one!
                </div>
            )}
        </div>
    );
} 