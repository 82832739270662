import React, { useEffect, useState } from 'react'

import { Button, Checkbox, Typography, Input, Tabs, TabsHeader, TabsBody, Tab, TabPanel, Switch, Collapse, Progress } from "@material-tailwind/react";
import { collection, addDoc } from "firebase/firestore";

import recommengineFeatureDescriptions from './recommengineFeatureDescriptions';

import { db, auth } from '../FirebaseConfig'

import activityManagementIcon from '../images/industries/icon-activity-management@2x.png'
import eventManagementIcon from '../images/industries/icon-event-management@2x.png'
import fenceBuildingIcon from '../images/industries/icon-fence-building@2x.png'
import glampingBusinessIcon from '../images/industries/icon-glamping-business@2x.png'
import handymanIcon from '../images/industries/icon-handyman@2x.png'
import hvacIcon from '../images/industries/icon-hvac@2x.png'
import lawnMowingIcon from '../images/industries/icon-lawn-mowing@2x.png'
import neighborhoodActivitiesIcon from '../images/industries/icon-neighborhood-activities@2x.png'
import nutritionalConsultingIcon from '../images/industries/icon-nutritional-consulting@2x.png'
import outdoorResearchIcon from '../images/industries/icon-outdoor-research@2x.png'
import pressureWashingIcon from '../images/industries/icon-pressure-washing@2x.png'
import radonMitigationIcon from '../images/industries/icon-radon-mitigation@2x.png'
import yardCleanupIcon from '../images/industries/icon-yard-cleanup@2x.png'

import General from '../layouts/General';

const totalSteps = 4;
const calculateProgress = (currentStep) => (currentStep / totalSteps) * 100;

function TemplateCard({ icon, title, onClick }) {
    return (
        <div 
            className='flex flex-col items-center justify-center p-4 border-2 border-gray-300 rounded-lg cursor-pointer hover:border-blue-500 hover:shadow-lg transition-all'
            onClick={onClick}
        >
            <img src={icon} alt={title} className='w-60 h-auto'/>
            <Typography variant='h5' className='mt-4'>{title}</Typography>
        </div>
    )
}

export function EngineGenerator({ statusMessage, refreshEngines }) {
    const [currentStep, setCurrentStep] = useState(1)
    const [step, setStep] = useState(1)
    const [engineName, setEngineName] = useState("")
    const [enginePackageName, setEnginePackageName] = useState("")
    const [newEngineId, setNewEngineId] = useState("")
    const [engineFeatures, setEngineFeatures] = useState({
        "map": false,
        "chat": false,
        "camera": false,
        "calendar": false,
        // "pushNotifications": false,
        // "onboardingQuestions": false,
        // "userProfile": false,
        // "social": false,
    })
    const [engineFeaturesDescription, setEngineFeaturesDescription] = useState([])
    const [errors, setErrors] = useState([])  
    const [searchTerm, setSearchTerm] = useState("");
    const [activeTab, setActiveTab] = useState("features");
    const [selectAll, setSelectAll] = useState(true);
    const [enginePurpose, setEnginePurpose] = useState("other")
    const [customPurpose, setCustomPurpose] = useState("")
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [examplesOpen, setExamplesOpen] = useState(false);

    useEffect(() => {
        setSelectAll(true);
        setEngineFeaturesDescription(recommengineFeatureDescriptions.map(f => f.description));
    }, [engineFeatures]);

    const validateForm = () => {
        switch(currentStep) {
            case 1:
                if (engineName) {
                    return true
                } else {
                    setErrors(["Name is required"])
                    return false
                }
            case 2:
                if (enginePurpose === "other" && !customPurpose) {
                    setErrors(["Please specify your purpose"])
                    return false
                }
                if (enginePurpose) {
                    return true
                } else {
                    setErrors(["Purpose is required"])
                    return false
                }
            default:
                return true
        }
    }

    const proceed = (step) => {
        console.log('proceed from current step ' + currentStep + ' to step ' + step)
        switch(currentStep) {
            case 1:
                if (validateForm()) {
                    setErrors([])
                    setStep(step)
                    setCurrentStep(step)
                }
                break
            case 2:
                if (validateForm()) {
                    setErrors([])
                    setStep(step)
                    setCurrentStep(step)
                }
                break;
            case 3:
                if (validateForm()) {
                    console.log('validated')
                    setErrors([])

                    new Promise(function(resolve) {
                        resolve(saveEngine(engineName, enginePackageName, engineFeatures, getFinalPurpose()))
                    }).then((result) => {
                        console.log('received result from step 3: ' + result)
                        if (result.id) {
                            console.log('engine saved: ' + result.id)
                            setNewEngineId(result.id)
                            if (refreshEngines) refreshEngines()
                            setStep(4)
                            setCurrentStep(4)
                        }
                    }).catch((error) => {
                        console.error("Error adding document: ", error);
                    })
                }
                break;
            default:
                setStep(step)
                setCurrentStep(step)
        }
    }
    
    const goToNewEngine = () => {
        // Navigate to the new engine page instead of dashboard
        window.location.href = `/engines/${newEngineId}`
    }

    // const storeFeature = (event, feature) => {
    //     // console.log('event: ' + event.target.checked)
    //     // console.log('feature: ' + feature)

    //     // setAppFeatures({ ...appFeatures, feature: !appFeatures.feature })
    //     if (event.target.checked) {
    //         setEngineFeatures({ ...engineFeatures, [feature]: true })   
    //     }  else {
    //         setEngineFeatures({ ...engineFeatures, [feature]: false })
    //     }

    //     // Group feature and description
    //     const featureDescriptions = recommengineFeatureDescriptions;

    //     // Find the feature description
    //     try {
    //         const featureDescription = featureDescriptions.find(f => f.name === feature).description

    //         if (event.target.checked) {
    //             setEngineFeaturesDescription([...engineFeaturesDescription, featureDescription])
    //             // console.log('Feature description: ' + featureDescription)
    //         } else {
    //             setEngineFeaturesDescription(engineFeaturesDescription.filter(f => f !== featureDescription))
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }   
    // }

    // const handleSelectAll = () => {
    //     const newSelectAll = !selectAll;
    //     setSelectAll(newSelectAll);
    //     const updatedFeatures = {};
    //     Object.keys(engineFeatures).forEach(feature => {
    //         updatedFeatures[feature] = newSelectAll;
    //     });
    //     setEngineFeatures(updatedFeatures);

    //     // Update feature descriptions
    //     const featureDescriptions = recommengineFeatureDescriptions;
    //     if (newSelectAll) {
    //         setEngineFeaturesDescription(featureDescriptions.map(f => f.description));
    //     } else {
    //         setEngineFeaturesDescription([]);
    //     }
    // };

    // const handleTemplateClick = (template) => {
    //     // Store the selected template
    //     setSelectedTemplate(template);

    //     // Enable all features
    //     const updatedFeatures = {};
    //     Object.keys(engineFeatures).forEach(feature => {
    //         updatedFeatures[feature] = true;
    //     });
    //     setEngineFeatures(updatedFeatures);
    //     setSelectAll(true);

    //     // Update feature descriptions
    //     const featureDescriptions = recommengineFeatureDescriptions;
    //     setEngineFeaturesDescription(featureDescriptions.map(f => f.description));

    //     // Set template-specific data
    //     setEnginePurpose(template.title);
        
    //     // Proceed to next step
    //     proceed(4);
    // };

    // const templates = [
    //     { icon: activityManagementIcon, title: "Activity Management" },
    //     { icon: eventManagementIcon, title: "Event Management Business" },
    //     { icon: yardCleanupIcon, title: "Yard Cleanup Services" },
    //     { icon: glampingBusinessIcon, title: "Glamping Business" },
    //     { icon: handymanIcon, title: "Handyman Business" },
    //     { icon: hvacIcon, title: "HVAC Business" },
    //     { icon: lawnMowingIcon, title: "Lawn Mowing Business" },
    //     { icon: neighborhoodActivitiesIcon, title: "Neighborhood Activities" },
    //     { icon: nutritionalConsultingIcon, title: "Nutritional Consulting" },
    //     { icon: outdoorResearchIcon, title: "Outdoor Research" },
    //     { icon: pressureWashingIcon, title: "Pressure Washing Business" },
    //     { icon: radonMitigationIcon, title: "Radon Mitigation Business" },
    //     { icon: fenceBuildingIcon, title: "Fence Building Business" },
    // ];

    // const filteredTemplates = templates.filter(template =>
    //     template.title.toLowerCase().includes(searchTerm.toLowerCase())
    // );

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            proceed(2);
        }
    };

    const getFinalPurpose = () => {
        return enginePurpose === "other" ? customPurpose : enginePurpose;
    }

    const registerEngine = async (engineName, enginePackageName, engineFeatures, enginePurpose) => {
        try {
            let userId = auth.currentUser.uid
            const docRef = await addDoc(collection(db, "recommengine/users/"+ userId +"/data/engines"), {
                name: engineName,
                packageName: enginePackageName,
                features: engineFeatures,
                purpose: enginePurpose,
                userId: auth.currentUser.uid,
                createdAt: new Date().toISOString(),
                archived: false,
                template: selectedTemplate?.title || null,
                featureDescriptions: engineFeaturesDescription,
            }).catch((error) => {
                console.error("Error adding document: ", error);
                return false
            })

            return docRef
        } catch (e) {
            console.error("Error adding document: ", e);
            return false
        }
    }

    const saveEngine = async (engineName, enginePackageName, engineFeatures, enginePurpose) => {
        console.log('save engine');
        return registerEngine(engineName, enginePackageName, engineFeatures, enginePurpose)
            .then((result) => {
                console.log(result);
                return result;
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
                return false;
            });
    }

    return (
        <General title="Create New Engine">
            <div className='max-w-4xl mx-auto'>
                <section className="bg-gradient-to-b from-military-green-50 via-earth-brown-50 to-white py-16 rounded-sm border border-military-green-200">
                    <div className='px-6'>
                        <div className="w-full mb-8">
                            <div className="mb-2 flex items-center justify-between gap-4">
                                <Typography color="blue-gray" variant="h6">
                                    Step {currentStep} of {totalSteps}
                                </Typography>
                                <Typography color="blue-gray" variant="h6">
                                    {calculateProgress(currentStep)}%
                                </Typography>
                            </div>
                            <Progress 
                                value={calculateProgress(currentStep)} 
                                size="lg"
                                className="border border-military-green-200"
                                color="green"
                            />
                        </div>

                        {step === 1 && (
                            <div className='flex flex-col'>
                                <div className='text-center mb-6'>
                                    <Typography variant='h3'>
                                        <span className="bg-gradient-to-r from-military-green-600 to-slate-blue-500 bg-clip-text text-transparent">
                                            What do you want to call your new engine?
                                        </span>
                                    </Typography>
                                    
                                    <Typography variant='paragraph' className='mt-4 text-military-green-600'>
                                        Create powerful engines for any purpose
                                    </Typography>

                                    {/* Collapsible Examples Section */}
                                    <Button 
                                        variant="text" 
                                        className="mt-2 text-military-green-600"
                                        onClick={() => setExamplesOpen(!examplesOpen)}
                                    >
                                        {examplesOpen ? "Hide Examples ▼" : "Show Examples ▶"}
                                    </Button>

                                    <Collapse open={examplesOpen}>
                                        <div className='mt-4 grid grid-cols-1 md:grid-cols-2 gap-6 max-w-2xl mx-auto text-left'>
                                            <div className='p-6 bg-military-green-50/30 rounded-lg border border-military-green-100'>
                                                <Typography variant='h6' className='text-military-green-700 mb-3'>
                                                    Personal Use
                                                </Typography>
                                                <ul className='list-disc list-inside space-y-1 text-military-green-600 text-sm'>
                                                    <li>Family activity tracking</li>
                                                    <li>Home maintenance logs</li>
                                                    <li>Homesteading management</li>
                                                    <li>Personal productivity</li>
                                                    <li>Household organization</li>
                                                </ul>
                                            </div>
                                            <div className='p-6 bg-military-green-50/30 rounded-lg border border-military-green-100'>
                                                <Typography variant='h6' className='text-military-green-700 mb-3'>
                                                    Business Use
                                                </Typography>
                                                <ul className='list-disc list-inside space-y-1 text-military-green-600 text-sm'>
                                                    <li>Service business management</li>
                                                    <li>Client tracking</li>
                                                    <li>Appointment scheduling</li>
                                                    <li>Project management</li>
                                                    <li>Team coordination</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>

                                <hr className='mb-6'/>

                                <div className='space-y-6'>
                                    <div className='max-w-xs mx-auto'>
                                        <Input 
                                            variant="outlined" 
                                            label="Name" 
                                            placeholder="Name" 
                                            value={engineName} 
                                            onChange={(e) => setEngineName(e.target.value)}
                                            // onKeyPress={handleKeyPress}
                                        />
                                    </div>
                                    
                                    <div className='text-center'>
                                        {errors.map((error, index) => (
                                            <Typography color="red" key={index}>{error}</Typography>
                                        ))}
                                    </div>

                                    <div className='text-center space-x-4'>
                                        <Button variant='outlined' onClick={() => window.location.href = '/engines'}>Back to Engines</Button>
                                        <Button onClick={() => proceed(2)}>Continue</Button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {step === 2 && (
                            <div className='flex flex-col'>
                                <div className='text-center mb-6'>
                                    <Typography variant='h3'>What is the main purpose of your engine?</Typography>
                                    <Typography variant='paragraph' className='mt-4 text-gray-700'>
                                        This helps the main prompt that drives your engine.
                                    </Typography>
                                </div>

                                <div className='space-y-6'>
                                    <div className='max-w-md mx-auto space-y-4'>
                                        {/* <select 
                                            className='w-full p-2 border rounded-lg'
                                            value={enginePurpose}
                                            onChange={(e) => {
                                                setEnginePurpose(e.target.value);
                                                if (e.target.value !== "other") {
                                                    setCustomPurpose("");
                                                }
                                            }}
                                        >
                                            <option value="">Select a purpose...</option>
                                            <option value="productivity">Maximize Productivity</option>
                                            <option value="customers">Track Customers</option>
                                            <option value="homestead">Maintain Home/Homestead</option>
                                            <option value="projects">Manage Projects</option>
                                            <option value="scheduling">Schedule Appointments</option>
                                            <option value="other">Other</option>
                                        </select> */}

                                        {/* {enginePurpose === "other" && ( */}
                                            <Input
                                                type="text"
                                                label="Purpose"
                                                value={customPurpose}
                                                onChange={(e) => setCustomPurpose(e.target.value)}
                                                className="mt-2"
                                            />
                                        {/* )} */}
                                    </div>

                                    <div className='text-center'>
                                        {errors.map((error, index) => (
                                            <Typography color="red" key={index}>{error}</Typography>
                                        ))}
                                    </div>

                                    <div className='text-center space-x-4'>
                                        <Button variant='outlined' onClick={() => setStep(1)}>Go Back</Button>
                                        <Button onClick={() => proceed(3)}>Continue</Button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {step === 3 && (
                            <div className='flex flex-col'>
                                <div className='text-center mb-6'>
                                    <Typography variant='h2'>Review Your Engine</Typography>

                                    <Typography variant='paragraph' className='mt-4 text-gray-700'>
                                        Review your engine and make sure everything looks good.
                                    </Typography>

                                    <Typography variant='paragraph' className='mt-4 text-gray-700'>
                                        {engineName}
                                    </Typography>

                                    <Typography variant='paragraph' className='mt-4 text-gray-700'>
                                        {getFinalPurpose()}
                                    </Typography>
                                </div>

                                {/* <Tabs value={activeTab} className="mb-6">
                                    <TabsHeader>
                                        <Tab value="features" onClick={() => setActiveTab("features")}>
                                            Individual Features
                                        </Tab>
                                        <Tab value="templates" onClick={() => setActiveTab("templates")}>
                                            Templates
                                        </Tab>
                                    </TabsHeader>
                                    <TabsBody>
                                        <TabPanel value="templates">
                                            <div className='mb-4'>
                                                <Input
                                                    type="text"
                                                    placeholder="Search templates..."
                                                    value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                />
                                            </div>

                                            <div className='flex flex-row flex-wrap items-center justify-center space-x-4 space-y-4'>
                                                {filteredTemplates.map((template, index) => (
                                                    <TemplateCard
                                                        key={index}
                                                        icon={template.icon}
                                                        title={template.title}
                                                        onClick={() => handleTemplateClick(template)}
                                                    />
                                                ))}
                                            </div>
                                        </TabPanel>
                                        <TabPanel value="features">
                                            <div className='mb-4 flex items-center justify-end'>
                                                <Typography color="blue-gray" className="mr-2">Select All</Typography>
                                                <Switch
                                                    checked={selectAll}
                                                    onChange={handleSelectAll}
                                                    label=""
                                                />
                                            </div>
                                            <div className='space-y-4'>
                                                <div>
                                                    <Checkbox
                                                        label={
                                                            <div>
                                                                <Typography color="blue-gray" className="font-medium">
                                                                    Map
                                                                </Typography>
                                                                <Typography variant="small" color="gray" className="font-normal">
                                                                    📍 Add interactive location features with searchable maps and position tracking.
                                                                </Typography>
                                                            </div>
                                                        }
                                                        onChange={(e) => storeFeature(e, "map")}
                                                        checked={engineFeatures.map || selectAll}
                                                        containerProps={{
                                                            className: "-mt-5",
                                                        }}
                                                    />
                                                </div>

                                                <div>
                                                    <Checkbox
                                                        label={
                                                            <div>
                                                            <Typography color="blue-gray" className="font-medium">
                                                                Chat
                                                            </Typography>
                                                            <Typography variant="small" color="gray" className="font-normal">
                                                                💬 Enable real-time messaging with a sleek interface and timestamp tracking.
                                                            </Typography>
                                                            </div>
                                                        }
                                                        onChange={(e) => storeFeature(e, "chat")}
                                                        checked={engineFeatures.chat || selectAll}
                                                        containerProps={{
                                                            className: "-mt-5",
                                                        }}
                                                    />
                                                </div>

                                                <div>
                                                    <Checkbox
                                                        label={
                                                            <div>
                                                            <Typography color="blue-gray" className="font-medium">
                                                                Camera
                                                            </Typography>
                                                            <Typography variant="small" color="gray" className="font-normal">
                                                                📷 Create stunning photo galleries with multi-upload support and auto-playing carousel.
                                                            </Typography>
                                                            </div>
                                                        }
                                                        onChange={(e) => storeFeature(e, "camera")}
                                                        checked={engineFeatures.camera || selectAll}
                                                        containerProps={{
                                                            className: "-mt-5",
                                                        }}
                                                    />
                                                </div>

                                                <div>
                                                    <Checkbox
                                                        label={
                                                            <div>
                                                            <Typography color="blue-gray" className="font-medium">
                                                                Calendar
                                                            </Typography>
                                                            <Typography variant="small" color="gray" className="font-normal">
                                                                📅 Display a calendar for event scheduling and date management.
                                                            </Typography>
                                                            </div>
                                                        }
                                                        onChange={(e) => storeFeature(e, "calendar")}
                                                        checked={engineFeatures.calendar || selectAll}
                                                        containerProps={{
                                                            className: "-mt-5",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </TabPanel>
                                    </TabsBody>
                                </Tabs> */}

                                <div className='text-center mt-8 space-x-4'>
                                    <Button variant='outlined' onClick={() => setStep(2)}>Go Back</Button>
                                    <Button onClick={() => proceed(4)}>Continue</Button>
                                </div>
                            </div>
                        )}

                        {step === 4 && (
                            <div className='flex flex-col'>
                                <div className='text-center mb-6'>
                                    <Typography variant='h2'>Success!</Typography>
                                </div>

                                <hr className='mb-6'/>

                                <div className='space-y-6 bg-white p-8 rounded-xl shadow-lg max-w-2xl mx-auto'>
                                    {selectedTemplate && (
                                        <div className='space-y-2'>
                                            <Typography variant="h6" color="blue-gray" className="font-bold uppercase tracking-wider text-sm">
                                                Selected Template
                                            </Typography>
                                            <div className="flex items-center space-x-4">
                                                <img src={selectedTemplate.icon} alt={selectedTemplate.title} className="w-16 h-16"/>
                                                <Typography color="blue-gray" className="font-medium text-xl">
                                                    {selectedTemplate.title}
                                                </Typography>
                                            </div>
                                        </div>
                                    )}

                                    <div className='space-y-2'>
                                        <Typography variant="h6" color="blue-gray" className="font-bold uppercase tracking-wider text-sm">
                                            Engine Name
                                        </Typography>
                                        <Typography color="blue-gray" className="font-medium text-xl">
                                            {engineName}
                                        </Typography>
                                    </div>

                                    <div className='space-y-2'>
                                        <Typography variant="h6" color="blue-gray" className="font-bold uppercase tracking-wider text-sm">
                                            Purpose
                                        </Typography>
                                        <Typography color="blue-gray" className="font-medium text-xl">
                                            {getFinalPurpose()}
                                        </Typography>
                                    </div>

                                    {/* <div className='space-y-2'>
                                        <Typography variant="h6" color="blue-gray" className="font-bold uppercase tracking-wider text-sm">
                                            Selected Features
                                        </Typography>
                                        <div className='space-y-2'>
                                            {engineFeaturesDescription.map((feature, index) => (
                                                <div key={index} className="flex items-center space-x-2">
                                                    <div className="w-2 h-2 bg-blue-500 rounded-full"></div>
                                                    <Typography color="blue-gray" className="font-medium">
                                                        {feature}
                                                    </Typography>
                                                </div>
                                            ))}
                                        </div>
                                    </div> */}

                                    {/* <div className='space-y-2'>
                                        <Typography variant="h6" color="blue-gray" className="font-bold uppercase tracking-wider text-sm">
                                            Chat with Rico
                                        </Typography>
                                        <div className='space-y-2'>
                                            <Typography color="blue-gray" className="font-medium text-xl">
                                                Included with all premium subscriptions.
                                            </Typography>
                                        </div>
                                    </div> */}
                                </div>

                                <div className='text-center mt-8 space-x-4'>
                                    <Button variant='outlined' onClick={() => setStep(3)}>Go Back</Button>
                                    <Button onClick={() => goToNewEngine()}>Go to Engine</Button>
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            </div>
        </General>
    );
}
