import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';

import { Button, Typography } from "@material-tailwind/react";
// import { getFirestore, collection, addDoc, query, where, getDocs } from "firebase/firestore";

import Header from '../components/Header'
import { Footer } from '../components/Footer'
import NavigationLinks from '../components/NavigationLinks'
import LoadingIndicator from '../components/LoadingIndicator';
import ChatSidebar from '../components/ChatSidebar';

import { db, auth } from '../FirebaseConfig'
import { navItems } from '../config/navigation';

export default function General({ title, children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [navSidebarOpen, setNavSidebarOpen] = useState(() => {
        const saved = localStorage.getItem('navSidebarOpen');
        return saved !== null ? JSON.parse(saved) : true;
    });
    const [mobileNavOpen, setMobileNavOpen] = useState(false);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);
    const [openSubMenu, setOpenSubMenu] = useState(() => {
        const saved = localStorage.getItem('openSubMenu');
        return saved !== null ? parseInt(saved) : null;
    });
    const [fullyCollapsed, setFullyCollapsed] = useState(false);

    const location = useLocation();

    useEffect(() => {
        localStorage.setItem('navSidebarOpen', JSON.stringify(navSidebarOpen));
    }, [navSidebarOpen]);

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if (user) {
                setCurrentUser(user);
                setLoading(false);
            } else {
                setCurrentUser(null);
                setLoading(false);
            }
        })
    }, [])

    useEffect(() => {
        const handleResize = () => {
            // Debounce the resize operation
            if (window.resizeTimeout) {
                clearTimeout(window.resizeTimeout);
            }
            window.resizeTimeout = setTimeout(() => {
                setIsDesktop(window.innerWidth >= 1024);
            }, 100);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            if (window.resizeTimeout) {
                clearTimeout(window.resizeTimeout);
            }
        };
    }, []);

    useEffect(() => {
        setNavSidebarOpen(isDesktop);
        if (isDesktop) {
            setMobileNavOpen(false);
        }
    }, [isDesktop]);

    useEffect(() => {
        localStorage.setItem('openSubMenu', openSubMenu);
    }, [openSubMenu]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Only handle clicks outside on mobile devices
            if (isDesktop) return;
            
            // Check if the click is outside of the navigation menu
            const navMenu = document.getElementById('navigation-menu');
            const isClickOnToggleButton = event.target.closest('button[aria-label*="menu"]');
            
            // Don't close if clicking the toggle button or if menu doesn't exist
            if (!navMenu || isClickOnToggleButton) return;
            
            // Close submenu if click is outside navigation
            if (!navMenu.contains(event.target)) {
                setOpenSubMenu(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [isDesktop]);

    useEffect(() => {
        // Find the index of the menu item that matches the current path
        const findMenuIndex = (path) => {
            const index = navItems.findIndex(item => {
                // Check if current path starts with the base path (e.g., /engines, /customers)
                const isBasePathMatch = path.startsWith(item.path) && item.path !== '#';
                // Check if current path matches any child path exactly
                const isChildPathMatch = item.children?.some(child => path === child.path);
                return isBasePathMatch || isChildPathMatch;
            });
            return index;
        };

        const menuIndex = findMenuIndex(location.pathname);
        
        if (menuIndex !== -1) {
            setOpenSubMenu(menuIndex);
        }
    }, [location.pathname]);

    if (loading) {
        return (
            <div className='flex flex-col w-full h-full'>
                <LoadingIndicator />
            </div>
        )
    }

    return (
        <div className='flex flex-col min-h-screen p-1 md:p-1 w-full relative touch-pan-x touch-pan-y'>
            <div className="sticky top-0 z-50 bg-white contain-layout">
                <Header 
                    sidebarOpen={sidebarOpen} 
                    setSidebarOpen={setSidebarOpen}
                    mobileNavOpen={mobileNavOpen}
                    setMobileNavOpen={setMobileNavOpen}
                />
            </div>
            {currentUser && (
                <>
                    <div className="flex flex-row flex-grow pb-16">
                        <div className={`fixed left-0 h-[calc(100vh-4rem)] bg-white shadow-lg transition-all duration-300 z-40 
                            ${navSidebarOpen ? 'w-64' : 'w-16'}
                            ${mobileNavOpen ? 'top-[calc(100vh-4rem)] lg:top-16' : 'top-16'}`}
                            style={{ width: fullyCollapsed ? '0px' : undefined }}>
                            <NavigationLinks 
                                collapsed={!navSidebarOpen} 
                                onNavigate={() => !isDesktop && setMobileNavOpen(false)}
                                openSubMenu={openSubMenu}
                                setOpenSubMenu={setOpenSubMenu}
                                fullyCollapsed={fullyCollapsed}
                                setFullyCollapsed={setFullyCollapsed}
                                user={currentUser}
                            />
                        </div>

                        <div className={`flex-grow transition-all duration-300 relative
                            ${!fullyCollapsed ? (navSidebarOpen ? 'ml-64' : 'ml-16') : 'ml-0'}
                            ${sidebarOpen && !fullyCollapsed ? 'mr-64' : 'mr-0'} 
                            px-4`}>
                            <div className="flex-grow px-4 mb-8">
                                {title && (
                                    <div className="relative mb-8 pl-8">
                                        <Typography variant="h6" className="uppercase tracking-wide text-gray-800 font-medium text-sm">
                                            {title}
                                        </Typography>
                                        <svg className="absolute -bottom-2 left-8 w-24 h-0.5" xmlns="http://www.w3.org/2000/svg">
                                            <defs>
                                                <linearGradient id="titleGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                                    <stop offset="0%" style={{ stopColor: '#2563eb', stopOpacity: 1 }} />
                                                    <stop offset="100%" style={{ stopColor: '#7c3aed', stopOpacity: 1 }} />
                                                </linearGradient>
                                            </defs>
                                            <rect width="100%" height="100%" fill="url(#titleGradient)" />
                                        </svg>
                                    </div>
                                )}
                                {children}
                            </div>
                        </div>

                        <div className={`fixed right-0 h-[calc(100vh-4rem)] bg-white shadow-lg transition-all duration-300 z-40 
                            ${sidebarOpen ? 'w-64 translate-x-0' : 'w-64 translate-x-full'}
                            ${mobileNavOpen ? 'top-[calc(100vh-4rem)] lg:top-16' : 'top-16'}`}>
                            <ChatSidebar isOpen={sidebarOpen} currentUser={currentUser} isCompact={true} />
                        </div>
                    </div>
                </>
            )}
            {!currentUser && (
                <div className="flex-grow pb-32">
                    {title && <Typography variant="h2" className="mb-4">{title}</Typography>}
                    {children}
                </div>
            )}
            {(!currentUser || (isDesktop && !sidebarOpen)) && <Footer />}
        </div>
    );
}
