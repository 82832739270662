import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import {
    Typography,
    // Card,
    // CardBody
} from "@material-tailwind/react";
// import { WrenchScrewdriverIcon, ClipboardDocumentListIcon, DocumentTextIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { BetaDownloadButtons } from './BetaDownloadButtons';
import Recommengine from './Recommengine';

export default function DashboardContent({ currentUser, myEngines, myInvoices, myCustomers, myRequests }) {
    const navigate = useNavigate();
    const [isWelcomeVisible, setIsWelcomeVisible] = useState(true);

    useEffect(() => {
        const welcomeDismissed = localStorage.getItem('welcomeDismissed');
        if (welcomeDismissed) {
            setIsWelcomeVisible(false);
        }
    }, []);

    const handleDismiss = () => {
        localStorage.setItem('welcomeDismissed', 'true');
        setIsWelcomeVisible(false);
    };

    return (
        <div className='flex flex-col gap-6'>
            {isWelcomeVisible && (
                <section className="bg-gradient-to-b from-military-green-50 via-earth-brown-50 to-white py-6 sm:py-8 rounded-lg border border-military-green-200 mb-6">
                    <div className='text-center space-y-4'>
                        <div>
                            <Typography variant="paragraph" className="text-military-green-600">
                                Welcome back{currentUser?.displayName ? `, ${currentUser.displayName}` : ''}
                            </Typography>
                            <button onClick={handleDismiss} className="dismiss-button">
                                Dismiss
                            </button>
                        </div>
                        <BetaDownloadButtons />
                    </div>
                </section>
            )}

            <div className="mb-6">
                <Recommengine currentUser={currentUser} />
            </div>

            {/* Cards Grid */}
            {/* <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6'>
                <Card 
                    className="bg-white shadow-sm hover:shadow-md transition-shadow cursor-pointer"
                    onClick={() => navigate('/engines')}
                >
                    <CardBody className="text-center">
                        <div className="space-y-4">
                            <WrenchScrewdriverIcon className="h-8 w-8 mx-auto text-military-green-700" />
                            <Typography variant="h4" color="blue-gray" className="text-military-green-700">
                                Engines
                            </Typography>
                            <Typography className="text-military-green-600">
                                {myEngines.length} Engines
                            </Typography>
                        </div>
                    </CardBody>
                </Card>

                <Card 
                    className="bg-white shadow-sm hover:shadow-md transition-shadow cursor-pointer"
                    onClick={() => navigate('/requests')}
                >
                    <CardBody className="text-center">
                        <div className="space-y-4">
                            <ClipboardDocumentListIcon className="h-8 w-8 mx-auto text-military-green-700" />
                            <Typography variant="h4" color="blue-gray" className="text-military-green-700">
                                Requests
                            </Typography>
                            <Typography className="text-military-green-600">
                                {myRequests.length} Requests
                            </Typography>
                        </div>
                    </CardBody>
                </Card>

                <Card 
                    className="bg-white shadow-sm hover:shadow-md transition-shadow cursor-pointer"
                    onClick={() => navigate('/invoices')}
                >
                    <CardBody className="text-center">
                        <DocumentTextIcon className="h-8 w-8 mx-auto text-military-green-700" />
                        <Typography variant="h4" color="blue-gray" className="text-military-green-700">
                            Invoices
                        </Typography>
                        <Typography className="text-military-green-600">
                            {myInvoices.length} Invoices
                        </Typography>
                    </CardBody>
                </Card>

                <Card 
                    className="bg-white shadow-sm hover:shadow-md transition-shadow cursor-pointer"
                    onClick={() => navigate('/customers')}
                >
                    <CardBody className="text-center">
                        <UserGroupIcon className="h-8 w-8 mx-auto text-military-green-700" />
                        <Typography variant="h4" color="blue-gray" className="text-military-green-700">
                            Customers
                        </Typography>
                        <Typography className="text-military-green-600">
                            {myCustomers.length} Customers
                        </Typography>
                    </CardBody>
                </Card>
            </div> */}
        </div>
    );
}
