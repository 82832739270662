import React, { useState, useEffect } from 'react'
import {
    Button,
    // Checkbox,
    Typography,
    // Input,
    // List,
    // ListItem,
    // Card,
    // Carousel,
    // CardBody,
} from "@material-tailwind/react";

import LoadingIndicator from '../LoadingIndicator';

import ChatAgent from '../chat/ChatAgent';
// import CalendarAgent from '../calendar/CalendarAgent';
// import CameraAgent from '../camera/CameraAgent';
// import MapAgent from '../map/MapAgent';
// import RegularButton from '../buttons/RegularButton';
import AgentCard from './AgentCard';

export default function AgentContent({ currentUser, agent, loading, currentAgent }) {
    const [activeAgent, setActiveAgent] = useState(null);
    const [hasChat, setHasChat] = useState(false);
    const [hasCalendar, setHasCalendar] = useState(false);
    const [hasCamera, setHasCamera] = useState(false);
    const [hasMap, setHasMap] = useState(false);

    // console.log('activeAgent: ', activeAgent);

    useEffect(() => {
        setActiveAgent(agent);
        console.log('agent id: ', agent?.id);
        if (agent?.features) {
            setHasChat(!!agent.features.chat);
            setHasCalendar(!!agent.features.calendar);
            setHasCamera(!!agent.features.camera);
            setHasMap(!!agent.features.map);
        }
    }, [agent]);

    // console.log('activeAgent: ', activeAgent);

    if (loading || !activeAgent) {
        return (
            <div className='flex flex-col w-full h-full'>
                <LoadingIndicator />
            </div>
        )
    }

    return (
        <div className='flex flex-col mt-2'>
            {/* <RegularButton filled={true} text="Back to Agents" url="/agents" /> */}
            {/* <Button onClick={() => {
                console.log('activeAgent: ', activeAgent);
            }}>Continue</Button> */}
            {/* <Typography variant='h2' className="mb-4">
                {activeAgent.name}
            </Typography> */}

            <Typography variant='h4' className="mb-4">
                {activeAgent.role}
            </Typography>

            {/* <div className="overflow-x-auto"> */}
                {/* {console.log('activeAgent.features: ', Object.keys(activeAgent.features))}
                {Object.keys(activeAgent.features).map((feature) => {
                    return (
                        <div key={feature}>
                            {feature}
                        </div>
                    )
                })} */}
                {/* {hasChat && <ChatAgent agent={activeAgent} currentUser={currentUser} />}
                {hasCalendar && <CalendarAgent agent={activeAgent} currentUser={currentUser} />}
                {hasCamera && <CameraAgent agent={activeAgent} currentUser={currentUser} />}
                {hasMap && <MapAgent agent={activeAgent} currentUser={currentUser} />} */}
            {/* </div> */}

            <AgentCard
                key={`agent-${activeAgent.id}`}
                agent={activeAgent}
                maxActivity={100}
            />

            {(activeAgent && activeAgent.id) && <ChatAgent agent={activeAgent} currentUser={currentUser} />}
        </div>
    );
}