import React from 'react';
import { Typography } from "@material-tailwind/react";

// Import all the icons
import activityManagementIcon from '../images/industries/icon-activity-management@2x.png'
import eventManagementIcon from '../images/industries/icon-event-management@2x.png'
import fenceBuildingIcon from '../images/industries/icon-fence-building@2x.png'
import glampingBusinessIcon from '../images/industries/icon-glamping-business@2x.png'
import handymanIcon from '../images/industries/icon-handyman@2x.png'
import hvacIcon from '../images/industries/icon-hvac@2x.png'
import lawnMowingIcon from '../images/industries/icon-lawn-mowing@2x.png'
import neighborhoodActivitiesIcon from '../images/industries/icon-neighborhood-activities@2x.png'
import nutritionalConsultingIcon from '../images/industries/icon-nutritional-consulting@2x.png'
import outdoorResearchIcon from '../images/industries/icon-outdoor-research@2x.png'
import pressureWashingIcon from '../images/industries/icon-pressure-washing@2x.png'
import radonMitigationIcon from '../images/industries/icon-radon-mitigation@2x.png'
import yardCleanupIcon from '../images/industries/icon-yard-cleanup@2x.png'

const businessTypes = [
  {
    title: "Home Services",
    icon: handymanIcon,
    description: "Perfect for independent contractors and repair services. Organize jobs, track time, manage client requests, and handle billing."
  },
  {
    title: "Adventure Planning",
    icon: outdoorResearchIcon,
    description: "Perfect for field adventurers, researchers and outdoor data collection. Record observations, track locations, and manage research projects."
  },
  {
    title: "Family Activities",
    icon: neighborhoodActivitiesIcon,
    description: "Great for families, community organizers and HOAs. Coordinate events, manage memberships, and facilitate neighborhood communications."
  },
  {
    title: "Group Management",
    icon: activityManagementIcon,
    description: "Perfect for small to medium-sized groups managing recreational activities, sports leagues, and community programs. Streamline participant registration, scheduling, and communication."
  },
  // {
  //   title: "Activity Management",
  //   icon: activityManagementIcon,
  //   description: "Perfect for organizations managing recreational activities, sports leagues, and community programs. Streamline participant registration, scheduling, and communication."
  // },
  // {
  //   title: "Event Management",
  //   icon: eventManagementIcon,
  //   description: "Ideal for event planners and coordinators. Manage bookings, vendors, schedules, and client communications all in one place."
  // },
  // {
  //   title: "Fence Building",
  //   icon: fenceBuildingIcon,
  //   description: "Tailored for fence installation companies. Handle estimates, material calculations, project timelines, and customer approvals efficiently."
  // },
  // {
  //   title: "Glamping Business",
  //   icon: glampingBusinessIcon,
  //   description: "Designed for luxury camping and outdoor hospitality businesses. Manage bookings, amenities, and guest services seamlessly."
  // },
  // {
  //   title: "Handyman Services",
  //   icon: handymanIcon,
  //   description: "Perfect for independent contractors and repair services. Organize jobs, track time, manage client requests, and handle billing."
  // },
  // {
  //   title: "HVAC Services",
  //   icon: hvacIcon,
  //   description: "Specialized for HVAC contractors. Schedule maintenance, track service history, and manage customer relationships effectively."
  // },
  // {
  //   title: "Lawn Mowing",
  //   icon: lawnMowingIcon,
  //   description: "Built for lawn care professionals. Schedule regular services, route optimization, and automated billing features."
  // },
  // {
  //   title: "Neighborhood Activities",
  //   icon: neighborhoodActivitiesIcon,
  //   description: "Great for community organizers and HOAs. Coordinate events, manage memberships, and facilitate neighborhood communications."
  // },
  // {
  //   title: "Nutritional Consulting",
  //   icon: nutritionalConsultingIcon,
  //   description: "Designed for nutrition professionals. Track client progress, meal planning, and consultation scheduling."
  // },
  // {
  //   title: "Outdoor Research",
  //   icon: outdoorResearchIcon,
  //   description: "Perfect for field researchers and outdoor data collection. Record observations, track locations, and manage research projects."
  // },
  // {
  //   title: "Pressure Washing",
  //   icon: pressureWashingIcon,
  //   description: "Tailored for pressure washing businesses. Handle job scheduling, estimates, and before/after photo documentation."
  // },
  // {
  //   title: "Radon Mitigation",
  //   icon: radonMitigationIcon,
  //   description: "Specialized for radon testing and mitigation services. Track measurements, schedule installations, and manage follow-up testing."
  // },
  // {
  //   title: "Yard Cleanup",
  //   icon: yardCleanupIcon,
  //   description: "Built for yard maintenance services. Schedule seasonal cleanups, manage client properties, and track service history."
  // }
];

export function BusinessTypeGrid({ handleOpenModal, scrollToForm }) {
  return (
    <div className='max-w-7xl mx-auto px-4'>
      <Typography variant='h2' className='text-center mb-4 text-3xl font-bold'>
        <span className="bg-gradient-to-r from-military-green-600 to-slate-blue-500 bg-clip-text text-transparent">
          Choose Your Use Case
        </span>
      </Typography>
      
      <div className="text-center mb-12">
        <Typography className="text-military-green-600 max-w-2xl mx-auto">
          These are just examples of common use cases. Our flexible engine architecture works for virtually any service-based business or organization. If you don't see your industry listed, you can still build a custom solution that fits your unique needs.
        </Typography>
      </div>
      
      <div className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 sm:gap-6'>
        {businessTypes.map((business, index) => (
          <BusinessCard 
            key={index}
            business={business}
            onClick={() => handleOpenModal(business)}
          />
        ))}
        <div className="col-span-2 grid grid-cols-2 gap-3 sm:gap-6">
          <ComingSoonCard />
          <CustomEngineCard onClick={() => scrollToForm('register')} />
        </div>
      </div>
    </div>
  );
}

function BusinessCard({ business, onClick }) {
  return (
    <div 
      onClick={onClick}
      className="group relative bg-white before:absolute before:inset-0 before:p-[1px] before:bg-gradient-to-r before:from-military-green-200 before:to-slate-blue-200 before:-z-10 hover:before:from-military-green-500 hover:before:to-slate-blue-500 transition-all duration-300 cursor-pointer"
    >
      <div className="flex flex-col">
        <div className="aspect-square bg-military-green-50/50 w-full flex items-center justify-center p-6">
          <img 
            src={business.icon} 
            alt={business.title} 
            className="w-full h-full object-contain opacity-75 group-hover:opacity-100 transition-opacity"
          />
        </div>
        <div className="p-4 text-center">
          <h3 className="text-lg font-semibold text-military-green-700 group-hover:text-military-green-800 transition-colors">
            {business.title}
          </h3>
        </div>
      </div>
    </div>
  );
}

function ComingSoonCard() {
  return (
    <div className="group bg-white border-2 border-military-green-200 rounded-sm transition-all duration-300">
      <div className="h-full flex flex-col items-center justify-center p-6 text-center">
        <div className="aspect-square bg-military-green-50/50 rounded-sm p-6 mb-4 flex items-center justify-center">
          <svg className="w-12 h-12 text-military-green-400" viewBox="0 0 24 24" fill="none" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>
        <h3 className="text-lg font-semibold text-military-green-700 text-center">
          More Templates<br />Coming Weekly
        </h3>
      </div>
    </div>
  );
}

function CustomEngineCard({ onClick }) {
  return (
    <div 
      onClick={onClick}
      className="group bg-white border-2 border-military-green-200 hover:border-military-green-400 rounded-sm transition-all duration-300 cursor-pointer relative overflow-hidden"
    >
      <div className="h-full flex flex-col items-center justify-center p-6 text-center">
        <div className="aspect-square bg-military-green-50/50 rounded-sm p-6 mb-4 flex items-center justify-center">
          <svg className="w-12 h-12 text-military-green-400" viewBox="0 0 24 24" fill="none" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>
        <h3 className="text-lg font-semibold text-military-green-700 group-hover:text-military-green-800 transition-colors text-center">
          Create Custom<br />Engine
        </h3>
      </div>
      <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-military-green-500 to-slate-blue-500 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></div>
    </div>
  );
}