import { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
// import { app } from '../../FirebaseConfig';
import { db } from '../../FirebaseConfig';
import General from '../../layouts/General';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
  Typography,
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

const Admin = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  // Add check for localhost
  const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

  useEffect(() => {
    // Only proceed with auth and fetching if on localhost
    if (!isLocalhost) return;

    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/login');
        return;
      }

      const email = user.email;
      const allowedDomains = ['briancary.com', 'bcpsolutionsllc.com'];
      const userDomain = email.split('@')[1];

      if (!allowedDomains.includes(userDomain)) {
        navigate('/');
        return;
      }

      // Only fetch users if authorized
      fetchUsers();
    });

    return () => unsubscribe();
  }, [navigate, isLocalhost]);

  const fetchUsers = async () => {
    try {
      const usersRef = collection(db, 'users');
      const snapshot = await getDocs(usersRef);
      const usersData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setUsers(usersData);
    } catch (err) {
      setError('Error fetching users: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = (userId) => {
    setSelectedUserId(userId);
    setOpenDialog(true);
  };

  const handleSendWelcomeEmail = () => {
    // Logic to send a welcome email
    console.log(`Sending welcome email to user with ID: ${selectedUserId}`);
    setOpenDialog(false);
  };

  const handleDisableUser = () => {
    console.log(`Disabling user with ID: ${selectedUserId}`);
    setOpenDialog(false);
  };

  if (loading) return <div>Loading users...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <General>
      {!isLocalhost ? (
        <div className="admin-container">
          <h1>Admin Dashboard</h1>
          <p>Admin dashboard is only available on localhost.</p>
        </div>
      ) : (
        <div className="admin-container">
          <h1>Admin Dashboard</h1>
          <div className="users-list">
            <h2>Users</h2>
            {users.length === 0 ? (
              <p>No users found</p>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>User ID</th>
                    <th>Email</th>
                    <th>Name</th>
                    <th>Auth Provider</th>
                    {/* <th>Created At</th> */}
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map(user => (
                    <tr key={user.id}>
                      <td>
                        <a 
                          href={`/admin/users/${user.id}`}
                          className="text-blue-600 hover:text-blue-800 underline"
                        >
                          {user.id}
                        </a>
                      </td>
                      <td>
                        <a 
                          href={`/admin/users/${user.id}`}
                          className="text-blue-600 hover:text-blue-800 underline"
                        >
                          {user.email}
                        </a>
                      </td>
                      <td>{user.name}</td>
                      <td>{user.authProvider}</td>
                      {/* <td>{user.createdAt?.toDate().toLocaleDateString()}</td> */}
                      <td>
                        <Button 
                          variant="gradient" 
                          color="blue" 
                          size="sm"
                          onClick={() => handleOpen(user.id)}
                        >
                          Send Welcome Email
                        </Button>
                        <Button 
                          variant="gradient" 
                          color="red" 
                          size="sm"
                          onClick={() => handleDisableUser(user.id)}
                        >
                          Disable User
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      )}

      <Dialog open={openDialog} handler={() => setOpenDialog(false)}>
        <DialogHeader>
          <Typography variant="h5">
            Confirm Email Send
          </Typography>
        </DialogHeader>
        <DialogBody divider>
          <Typography variant="paragraph" color="gray">
            Are you sure you want to send a welcome email to this user?
          </Typography>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <Button 
            variant="text" 
            color="gray" 
            onClick={() => setOpenDialog(false)}
          >
            Cancel
          </Button>
          <Button 
            variant="gradient" 
            color="blue" 
            onClick={handleSendWelcomeEmail}
          >
            Send Email
          </Button>
        </DialogFooter>
      </Dialog>
    </General>
  );
};

export default Admin;
