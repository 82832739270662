import React, { useEffect, useState } from 'react'
import LoadingIndicator from '../LoadingIndicator'
import General from '../../layouts/General'
import WorkflowContent from './WorkflowContent'
import { db, auth, app } from '../../FirebaseConfig'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { Link } from 'react-router-dom'

const getMyWorkflows = async (userId) => {
    const functions = getFunctions(app);
    const getMyWorkflows = httpsCallable(functions, 'getMyWorkflows');
    
    const result = await getMyWorkflows({
        uid: userId
    });

    if (result.data) {
        const activeWorkflows = result.data.filter(workflow => !workflow.data.archived);
        return activeWorkflows;
    } else {
        console.log("Error getting workflows");
        return [];
    }
}

export default function Workflows() {
    const [myWorkflows, setMyWorkflows] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [fetchingWorkflows, setFetchingWorkflows] = useState(false);

    const refreshWorkflows = async (userId) => {
        setFetchingWorkflows(true);
        const workflows = await getMyWorkflows(userId);
        setMyWorkflows(workflows);
        setFetchingWorkflows(false);
    };

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if (user) {
                setCurrentUser(user);
                refreshWorkflows(user.uid);
            } else {
                setCurrentUser(null);
            }
            setLoading(false);
        })        
    }, [])

    if (loading) {
        return <LoadingIndicator />;
    }

    return (
        <General title="Workflows">
            {currentUser && (
                fetchingWorkflows ? (
                    <LoadingIndicator />
                ) : (
                    <WorkflowContent 
                        currentUser={currentUser} 
                        myWorkflows={myWorkflows} 
                        refreshWorkflows={refreshWorkflows} 
                    />
                )
            )}
        </General>
    )
}
