import React, { useState, useEffect } from 'react';
import { Progress, List, ListItem, ListItemPrefix, Typography } from "@material-tailwind/react";
import { CheckCircleIcon, ArrowRightCircleIcon, ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useAuth } from '../../contexts/AuthContext';

export default function NewUser() {
    const [isExpanded, setIsExpanded] = useState(true);
    const [userStatus, setUserStatus] = useState(null);
    const { currentUser } = useAuth();
    
    const getUserStatus = async () => {
        const functions = getFunctions();
        const getUserStatusFn = httpsCallable(functions, 'getUserStatus');
        try {
            const result = await getUserStatusFn({ uid: currentUser.uid });
            return result.data;
        } catch (error) {
            console.error('Error fetching user status:', error);
            return null;
        }
    }

    useEffect(() => {
        if (currentUser) {
            getUserStatus().then((status) => {
                setUserStatus(status);
            });
        }
    }, [currentUser]);

    // Calculate progress based on userStatus
    const calculateProgress = () => {
        if (!userStatus) return 0;
        
        const steps = [
            userStatus.hasVerifiedEmail,
            userStatus.hasCreatedAgent,
            userStatus.hasCreatedEngine,
            userStatus.hasSentMessage,
            userStatus.hasActiveSubscription
        ];
        
        const completedSteps = steps.filter(step => step).length;
        return Math.round((completedSteps / steps.length) * 100);
    };

    const steps = [
        { 
            title: "Get started", 
            completed: userStatus?.hasVerifiedEmail || false, 
            url: "/engines/new" 
        },
        { 
            title: "Create your first agent", 
            completed: userStatus?.hasCreatedAgent || false, 
            url: "/agents/new" 
        },
        { 
            title: "Create your first engine", 
            completed: userStatus?.hasCreatedEngine || false, 
            url: "/engines/new" 
        },
        { 
            title: "Send your first message", 
            completed: userStatus?.hasSentMessage || false, 
            url: "/engines/new" 
        },
        { 
            title: "Unlock all premium features", 
            completed: userStatus?.hasActiveSubscription || false, 
            url: "/subscription/new" 
        }
    ];

    const progress = calculateProgress();

    return (
        <div className="p-3 bg-white rounded-lg shadow-sm border border-blue-gray-50">
            <div className="flex justify-between items-center mb-1">
                <Typography variant="h6" color="blue-gray" className="text-sm font-semibold">
                    Onboarding Progress
                </Typography>
                <button 
                    onClick={() => setIsExpanded(!isExpanded)} 
                    className="text-blue-gray-500"
                >
                    {isExpanded ? 
                        <ChevronUpIcon className="h-3.5 w-3.5" /> : 
                        <ChevronDownIcon className="h-3.5 w-3.5" />
                    }
                </button>
            </div>
            {isExpanded && (
                <>
                    <div className="mb-2">
                        <div className="flex justify-between items-center mb-1">
                            <Typography variant="h6" color="blue-gray">
                                {progress}%
                            </Typography>
                        </div>
                        <Progress 
                            value={progress} 
                            size="sm" 
                            color="blue" 
                            className="border border-blue-gray-50"
                        />
                    </div>

                    <List className="min-w-0 p-0">
                        {steps.map((step, index) => (
                            <ListItem key={index} className="group py-0.5 px-0">
                                <ListItemPrefix>
                                    {step.completed ? (
                                        <CheckCircleIcon className="h-4 w-4 text-blue-500" /> 
                                    ) : (
                                        <ArrowRightCircleIcon className="h-4 w-4 text-blue-gray-300" />
                                    )}
                                </ListItemPrefix>
                                <Typography
                                    onClick={() => {
                                        if (step.url) {
                                            window.location.href = step.url;
                                        }
                                    }}
                                    color={step.completed ? "blue-gray" : "blue-gray"}
                                    className={`font-medium text-sm ${step.completed ? "" : "opacity-70"}`}
                                >
                                    {step.title}
                                </Typography>
                            </ListItem>
                        ))}
                    </List>
                </>
            )}
        </div>
    );
}
