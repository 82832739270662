import React, { useEffect, useRef, useState } from 'react';
import { PlayCircleIcon, PauseCircleIcon, ArrowPathIcon } from "@heroicons/react/24/solid";
import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app, firebaseConfig } from '../../FirebaseConfig';
import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from "@material-tailwind/react";

export default function SpeechControl({ text, currentUser, engineId, agentId, onRef }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);
    const audioRef = useRef(new Audio());
    const [error, setError] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);

    useEffect(() => {
        if (onRef === true && text && currentUser && !isPlaying && !isGenerating) {
            console.log('Auto-speak triggered:', {
                hasAudioUrl: !!audioUrl,
                hasValidUrl: audioRef.current?.src && audioRef.current.src !== window.location.href,
                text: text?.substring(0, 50) + '...',
                userId: currentUser?.uid
            });

            console.log('audioRef.current.src', audioRef.current.src);
            
            // Only proceed if we have a valid audio URL
            if (audioUrl && audioRef.current?.src && audioRef.current.src !== window.location.href) {
                console.log('Using existing audioUrl:', audioUrl);
                playAudio();
            } else if (!audioUrl && onRef) {
                console.log('No audio URL available, generating speech...');
                handleTextToSpeech();
            }
        }
    }, [onRef, text, currentUser, isPlaying, audioUrl]);

    const handleTextToSpeech = async () => {
        if (!text || !currentUser || isGenerating) {
            console.log('Skipping generation:', { 
                hasText: !!text, 
                hasUser: !!currentUser, 
                isGenerating 
            });
            return;
        }
        setIsGenerating(true);
        setIsLoading(true);
        
        try {
            console.log('handleTextToSpeech triggered');
            if (!text || !currentUser) {
                console.log('Missing required props:', { hasText: !!text, hasUser: !!currentUser });
                return;
            }

            console.log('Generating speech for:', {
                textLength: text.length,
                userId: currentUser.uid,
                engineId
            });

            const functions = getFunctions(app);
            const generateSpeech = httpsCallable(functions, 'generateSpeech');
            
            const result = await generateSpeech({
                text: text,
                userId: currentUser.uid,
                engineId: engineId,
                agentId: agentId
            });

            console.log('Speech generation result:', result.data); // Debug log

            if (!result.data || !result.data.filename) {
                throw new Error('Invalid API response: Missing filename');
            }

            const storage = getStorage(app);
            const audioPath = `recordings/${currentUser.uid}/${engineId}/${result.data.filename}`;
            console.log('Audio path:', audioPath); // Debug log
            
            const audioFileRef = storageRef(storage, audioPath);
            const url = await getDownloadURL(audioFileRef);
            
            console.log('Retrieved audio URL:', url); // Debug log

            // Validate URL before creating Audio instance
            const response = await fetch(url, { method: 'HEAD' });
            const contentType = response.headers.get('content-type');
            console.log('Content type:', contentType); // Debug log
            
            if (!contentType || !contentType.startsWith('audio/')) {
                throw new Error(`Invalid content type: ${contentType}`);
            }

            // Create new Audio instance with explicit type
            const newAudio = new Audio();
            newAudio.crossOrigin = "anonymous"; // Add CORS header
            newAudio.src = url;
            
            // Wait for the audio to be loaded
            await new Promise((resolve, reject) => {
                newAudio.addEventListener('loadedmetadata', () => {
                    console.log('Audio metadata loaded successfully'); // Debug log
                    resolve();
                }, { once: true });
                newAudio.addEventListener('error', (e) => {
                    console.error('Audio loading error:', e); // Debug log
                    reject(new Error(`Audio loading failed: ${e.target.error?.message || 'Unknown error'}`));
                }, { once: true });
            });

            // Only update the audioRef after successful loading
            audioRef.current = newAudio;
            setAudioUrl(url);
            
            // Automatically play the audio after generation
            await playAudio();
            
        } catch (error) {
            console.error("Error generating speech:", {
                error,
                message: error.message,
                code: error.code,
                stack: error.stack // Added stack trace
            });
            const errorMessage = error.message || "An error occurred while generating speech";
            setError(errorMessage);
            setOpenDialog(true);
        } finally {
            setIsLoading(false);
            setIsGenerating(false);
        }
    };

    const playAudio = async () => {
        console.log('playAudio triggered');
        // Add validation to prevent playing invalid URLs
        if (!audioRef.current.src || audioRef.current.src === window.location.href) {
            console.log('Invalid audio source:', {
                currentSrc: audioRef.current.src,
                audioUrl,
                isLocalhost: audioRef.current.src === window.location.href
            });
            return;
        }
        
        console.log('Attempting to play audio:', {
            src: audioRef.current.src,
            readyState: audioRef.current.readyState,
            isValidUrl: audioRef.current.src !== window.location.href
        });

        try {
            setIsLoading(true);
            // Ensure the audio is fully loaded before playing
            await new Promise((resolve, reject) => {
                audioRef.current.load();
                audioRef.current.onloadeddata = resolve;
                audioRef.current.onerror = reject;
            });
            
            await audioRef.current.play();
            console.log('Audio playing successfully');
            setIsPlaying(true);
        } catch (error) {
            console.error("Error playing audio:", {
                error,
                name: error.name,
                message: error.message
            });
            if (error.name !== 'NotAllowedError' && error.name !== 'AbortError') {
                setError("Failed to play audio: " + error.message);
                setOpenDialog(true);
            }
            setIsPlaying(false);
        } finally {
            setIsLoading(false);
        }
    };

    const toggleAudio = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    useEffect(() => {
        const audio = audioRef.current;
        
        // Add event listener for when audio finishes playing
        audio.onended = () => {
            console.log('Audio playback ended');
            setIsPlaying(false);
        };
        
        // Add error handler
        audio.onerror = (e) => {
            console.error("Audio error:", e);
            setIsPlaying(false);
            setError("Error playing audio");
        };
        
        // Cleanup function - only remove event listeners
        return () => {
            audio.onended = null;
            audio.onerror = null;
        };
    }, []);

    // Add separate useEffect for component unmount cleanup
    useEffect(() => {
        return () => {
            const audio = audioRef.current;
            audio.pause();
            audio.src = '';
            audio.load();
            audioRef.current = new Audio();
        };
    }, []); // This will only run on unmount

    return (
        <>
            <button
                onClick={handleTextToSpeech}
                className="p-2 rounded-full hover:bg-gray-100"
                disabled={isLoading}
            >
                {isLoading ? (
                    <ArrowPathIcon className="h-6 w-6 text-military-green-600 animate-spin" />
                ) : isPlaying ? (
                    <PauseCircleIcon className="h-6 w-6 text-military-green-600" />
                ) : (
                    <PlayCircleIcon className="h-6 w-6 text-military-green-600" />
                )}
            </button>

            <Dialog open={openDialog} handler={() => setOpenDialog(false)}>
                <DialogHeader>Error</DialogHeader>
                <DialogBody>
                    {error || ""}
                </DialogBody>
                <DialogFooter>
                    <Button 
                        variant="text" 
                        color="red" 
                        onClick={() => setOpenDialog(false)}
                        className="mr-1"
                    >
                        Close
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    );
} 