import React from 'react';
import { Typography } from "@material-tailwind/react";

const AgentCard = ({ agent, maxActivity }) => {
    const getTotalActivity = () => {
        return (agent.positionsCount || 0) + 
               (agent.images?.length || 0) + 
               (agent.calendarEventsCount || 0) + 
               (agent.messageCount || 0);
    };

    const getUtilizationPercentage = () => {
        const total = getTotalActivity();
        return Math.round((total / maxActivity) * 100);
    };

    const renderUtilizationIcon = (percentage) => {
        if (percentage > 66) {
            return <i className="fas fa-check-circle text-green-600" />;
        }
        if (percentage > 33) {
            return <i className="fas fa-exclamation-circle text-yellow-600" />;
        }
        return <i className="fas fa-exclamation-triangle text-red-600" />;
    };

    const getUtilizationColor = (percentage) => {
        if (percentage > 66) return 'fill-green-600';
        if (percentage > 33) return 'fill-yellow-600';
        return 'fill-red-600';
    };

    const percentage = getUtilizationPercentage();

    return (
            <div className="flex flex-col space-y-2">
                <div className="flex items-center space-x-4">
                    <div 
                        className="w-8 h-8 rounded-full flex-shrink-0" 
                        style={{ backgroundColor: agent.color || '#000000' }}
                    />
                    <div>
                        <Typography variant="small" color="blue-gray" className="font-bold text-base">
                            {agent.name}
                        </Typography>
                        <Typography variant="small" color="blue-gray" className="text-base">
                            {agent.description}
                        </Typography>
                        <Typography variant="small" color="gray" className="text-sm">
                            {/* {agent.createdAt 
                                ? `Created ${getDaysAgo(agent.createdAt)}`
                                : 'Creation date unknown'} */}
                        </Typography>
                        {/* <div className="grid grid-cols-2 sm:grid-cols-4 sm:flex sm:space-x-4 gap-y-1 gap-x-4 mt-2">
                            <MetricItem color="blue" value={agent.positionsCount} label="locations" />
                            <MetricItem color="green" value={agent.images?.length} label="photos" />
                            <MetricItem color="orange" value={agent.calendarEventsCount} label="events" />
                            <MetricItem color="purple" value={agent.messageCount} label="messages" />
                            <div className="flex items-center gap-1 col-span-2 sm:col-span-1">
                                <svg className="w-2 h-2" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="4" cy="4" r="4" className={getUtilizationColor(percentage)} />
                                </svg>
                                <Typography variant="small" color="gray" className="text-xs sm:text-sm whitespace-nowrap flex items-center gap-1">
                                    {renderUtilizationIcon(percentage)}
                                    <span className="text-gray-900">{percentage}% utilization</span>
                                </Typography>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        
    );
};

const MetricItem = ({ color, value, label }) => (
    <div className="flex items-center gap-1">
        <div className={`w-2 h-2 rounded-full bg-${color}-500`}/>
        <Typography variant="small" color="gray" className="text-xs sm:text-sm whitespace-nowrap">
            {value || 0} {label}
        </Typography>
    </div>
);

export default AgentCard; 