import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import General from "../../layouts/General";
import { useNavigate } from 'react-router-dom';

function CheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="h-12 w-12"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
}

export function SubscriptionSuccess() {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const mode = queryParams.get('mode') || 'subscription';

  const getMessage = () => {
    if (mode === 'subscription') {
      return {
        title: 'Subscription Successful!',
        mainText: 'Your subscription has been successfully processed.',
        details: 'You will receive a confirmation email shortly with all the details. You can now start using all the features included in your plan.'
      };
    }
    return {
      title: 'Purchase Successful!',
      mainText: 'Your purchase has been successfully processed.',
      details: 'You will receive a confirmation email shortly with all the details.'
    };
  };

  const message = getMessage();

  return (
    <General>
      <div className="flex justify-center p-8">
        <Card color="green" variant="gradient" className="w-full max-w-[30rem] p-8">
          <CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            className="m-0 mb-8 rounded-none border-b border-white/10 pb-8 text-center"
          >
            <div className="flex justify-center mb-4">
              <CheckIcon />
            </div>
            <Typography
              variant="h3"
              color="white"
              className="font-normal"
            >
              {message.title}
            </Typography>
          </CardHeader>
          <CardBody className="p-0">
            <div className="text-center">
              <Typography
                variant="h6"
                color="white"
                className="font-normal mb-4"
              >
                {message.mainText}
              </Typography>
              <Typography
                color="white"
                className="font-normal opacity-80"
              >
                {message.details}
              </Typography>
            </div>
          </CardBody>
          <CardFooter className="mt-12 p-0 flex gap-4 justify-center">
            <Button
              size="lg"
              color="white"
              className="hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
              ripple={false}
              onClick={() => navigate('/')}
            >
              Go to Dashboard
            </Button>
          </CardFooter>
        </Card>
      </div>
    </General>
  );
}

export default SubscriptionSuccess; 