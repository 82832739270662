import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemPrefix } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import { navItems } from '../config/navigation';
import NewUser from './onboarding/NewUser';
import UsageStats from './onboarding/UsageStats';

export default function NavigationLinks({ 
    collapsed, 
    onNavigate, 
    openSubMenu, 
    setOpenSubMenu,
    fullyCollapsed,
    setFullyCollapsed,
    user
}) {
    const [isAnimating, setIsAnimating] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setIsScrolled(scrollPosition > 100);
            
            // Auto-collapse navigation on mobile when scrolling down
            if (window.innerWidth < 768 && scrollPosition > 100) { // 768px is typical mobile breakpoint
                setFullyCollapsed(true);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [setFullyCollapsed]);

    const handleMenuClick = (index) => {
        setIsAnimating(true);
        setOpenSubMenu(openSubMenu === index ? null : index);
        setTimeout(() => setIsAnimating(false), 300);
    };

    return (
        <List id="navigation-menu" className={`relative min-w-[32px] transition-all duration-300 
            ${isScrolled ? 'md:min-w-[32px]' : ''}`}>
            <button
                onClick={() => setFullyCollapsed(!fullyCollapsed)}
                className={`absolute ${fullyCollapsed ? 'left-1' : '-right-3'} top-1 bg-blue-gray-50 
                    rounded-full p-0.5 z-50 hover:bg-blue-gray-100 shadow-sm text-sm`}
                aria-label={fullyCollapsed ? "Expand menu" : "Collapse menu fully"}
                style={{ transform: fullyCollapsed ? 'none' : 'translateX(100%)' }}
            >
                {fullyCollapsed ? '→' : '←'}
            </button>

            {!fullyCollapsed && (
                <div className="transition-all duration-300 pb-8 flex flex-col h-full">
                    <div className="flex-grow overflow-y-auto">
                        {navItems
                            .filter(item => !item.showIf || item.showIf(user))
                            .map((item, index) => (
                            <React.Fragment key={index}>
                                {item.children ? (
                                    // Parent item with children
                                    <div className="relative group">
                                        <ListItem 
                                            className={`hover:bg-blue-gray-50 cursor-pointer group relative py-0.5 uppercase text-sm ${
                                                collapsed ? 'w-fit px-2' : ''
                                            } ${fullyCollapsed ? 'w-0 overflow-hidden' : ''}`}
                                            onClick={() => handleMenuClick(index)}
                                            aria-label={collapsed ? item.title : undefined}
                                        >
                                            <ListItemPrefix>{item.icon}</ListItemPrefix>
                                            {!collapsed ? (
                                                <>
                                                    <span className="mr-4">
                                                        {item.title}
                                                        {(item.title === 'Requests' || 
                                                          item.title === 'Invoices' || 
                                                          item.title === 'Customers' ||
                                                          item.title === 'Workflows' ||
                                                          item.title === 'Engines') && (
                                                            <span className="ml-1 px-1 py-0.25 text-[10px] bg-blue-gray-100 text-blue-gray-700 rounded uppercase font-medium">
                                                                pro
                                                            </span>
                                                        )}
                                                    </span>
                                                    <span className="ml-auto mr-2">
                                                        {openSubMenu === index ? '▼' : '▶'}
                                                    </span>
                                                </>
                                            ) : null}
                                        </ListItem>
                                    </div>
                                ) : (
                                    <Link 
                                        to={item.path} 
                                        className="block"
                                        aria-label={collapsed ? item.title : undefined}
                                        onClick={onNavigate}
                                    >
                                        <ListItem className={`hover:bg-blue-gray-50 py-0.5 uppercase text-sm ${
                                            collapsed ? 'w-fit px-2' : ''
                                        }`}>
                                            <ListItemPrefix>{item.icon}</ListItemPrefix>
                                            {!collapsed && (
                                                <span>
                                                    {item.title}
                                                    {(item.title === 'Requests' || 
                                                      item.title === 'Invoices' || 
                                                      item.title === 'Customers' ||
                                                      item.title === 'Workflows' ||
                                                      item.title === 'Engines') && (
                                                        <span className="ml-1 px-1 py-0.25 text-[10px] bg-blue-gray-100 text-blue-gray-700 rounded uppercase font-medium">
                                                            pro
                                                        </span>
                                                    )}
                                                </span>
                                            )}
                                        </ListItem>
                                    </Link>
                                )}

                                {item.children && openSubMenu === index && (
                                    <div className={`
                                        transition-all duration-200 ease-in-out
                                        ${collapsed 
                                            ? 'absolute left-full ml-1 bg-white shadow-lg rounded-lg p-1 z-50 min-w-[180px] block opacity-100 translate-x-0'
                                            : 'ml-3 opacity-100 max-h-[500px]'
                                        }
                                        ${!openSubMenu && collapsed
                                            ? 'opacity-0 translate-x-[-10px]'
                                            : ''
                                        }
                                        ${!openSubMenu && !collapsed
                                            ? 'opacity-0 max-h-0 overflow-hidden'
                                            : ''
                                        }`} 
                                        style={collapsed ? { top: `${(index * 28) - 4}px` } : {}}>
                                        {collapsed && (
                                            <div className="px-2 py-1 font-semibold text-gray-700 border-b border-gray-200 mb-1">
                                                {item.title}
                                            </div>
                                        )}
                                        {item.children.map((child, childIndex) => (
                                            <Link 
                                                key={childIndex} 
                                                to={child.path}
                                                onClick={() => {
                                                    if (window.innerWidth < 768) {
                                                        setOpenSubMenu(null);
                                                    }
                                                    onNavigate();
                                                }}
                                                className="block transform transition-all duration-200"
                                                style={{
                                                    ...(isAnimating && {
                                                        opacity: 0,
                                                        animation: 'slideIn 0.2s ease-out forwards',
                                                        animationDelay: `${childIndex * 50}ms`
                                                    })
                                                }}
                                            >
                                                <ListItem className="hover:bg-blue-gray-50 py-0.5 uppercase text-sm">
                                                    <ListItemPrefix>
                                                        {collapsed ? '' : '└─'}
                                                    </ListItemPrefix>
                                                    {child.title}
                                                </ListItem>
                                            </Link>
                                        ))}
                                    </div>
                                )}

                                {/* Add separator between items */}
                                <div className="h-px bg-blue-gray-100 my-1"></div>
                            </React.Fragment>
                        ))}
                    </div>
                    <div className="hidden md:block space-y-4 mt-auto">
                        <UsageStats />
                        <NewUser />
                    </div>
                </div>
            )}
        </List>
    );
}